.b-news {
	$p: 20px;
	$p_vert-box-content: 25px;

	margin-bottom: 100px;

	@include md-tablet {
		margin-bottom: 80px; }

	@include md-mobile {
		margin-bottom: 70px; }

	&_last {
		margin-bottom: 0; }

	&__list {
		display: flex; // to collapse margins inside
		flex-direction: column;

		margin-bottom: 40px;
		padding: 0 $p;

		@include md-tablet {
			margin-bottom: 40px - $p_vert-box-content; }

		&_last {
			margin-bottom: 0; } }

	&__list-inner {
		display: flex;
		flex-wrap: wrap;
		margin: -$p / 2;

		@include md-mobile {
			margin: 0; } }

	&__list-item {
		width: calc( 50% - #{ $p } );
		margin: $p / 2;

		@include md-tablet_sm {
			width: 100%; }

		@include md-mobile {
			margin: 0; } }

	&__box { // tag a
		position: relative; // for &::before and &__box-date
		display: block;

		&::before {
			content: '';

			position: absolute;
			top: -$p;
			left: -$p;

			opacity: 0;

			width: calc( 100% + #{ $p * 2 } );
			height: calc( 100% + #{ $p } );

			background: $white;
			box-shadow: 0px 15px 40px rgba( $b_sh_c, .12 );

			transition: opacity .2s;

			@include md-mobile {
				display: none; } }

		&:hover::before {
			opacity: 1; } }

	&__box-inner {
		position: relative;
		z-index: 1; } // to be over &__box::before

	&__box-date {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 2; // to be over &__visual

		min-width: 200px;

		padding: 28px 30px;

		background-color: $white;

		@include md-desk_sm {
			min-width: 165px;
			padding: 22px 25px; }

		@include md-mobile {
			min-width: 160px;
			padding: 17px 25px 18px; }

		&::before {
			content: '';

			position: absolute;
			top: 0;
			right: 100%;

			border: 35px solid transparent;
			border-top-color: $brand-primary;
			border-right-color: $brand-primary;

			@include md-desk_sm {
				border-width: 28px; }

			@include md-mobile {
				border-width: 23px; } } }

	&__box-date-text { // tag time
		display: block;

		margin: -7px 0;

		color: $black;
		font-size: 20px;
		line-height: 28px;
		font-weight: 600;

		text-align: center;

		@include md-desk_sm {
			margin: -5px 0 -7px;
			font-size: 16px;
			line-height: 24px; }

		@include md-mobile {
			margin: -5px 0 -6px;
			font-size: 15px;
			line-height: 22px; } }

	&__visual {
		position: relative; } // for &__visual-details and &__visual-img

	&__visual-details { // tag a
		position: absolute;
		top: 15px;
		left: 15px;
		z-index: 1;

		display: flex;
		align-items: center;
		justify-content: center;

		width: calc( 100% - 30px );
		height: calc( 100% - 30px );

		&::before {
			content: '';

			position: absolute;
			top: 0;
			left: 0;

			width: 100%;
			height: 100%;

			background-color: rgba( $black, .5 );

			transform: scale( 0 );

			transition: transform .2s; } }

	&__box:hover &__visual-details::before {
		transform: scale( 1 ); }

	&__visual-details-text {
		position: relative;
		z-index: 1; // to be over &__visual-details::before

		opacity: 0;

		margin: -8px 0 -10px;

		color: $white;
		font-size: 30px;
		line-height: 40px;
		font-weight: 700;
		letter-spacing: .1em;

		text-transform: uppercase;

		transition: opacity .2s;

		@include md-desk_sm {
			margin: -9px 0 -8px;
			font-size: 28px;
			line-height: 36px; }

		@include md-tablet {
			margin: -7px 0;
			font-size: 25px;
			line-height: 32px; } }

	&__box:hover &__visual-details-text {
		opacity: 1; }

	&__visual-img {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		object-fit: cover; }

	&__visual-img-plug {
		padding-top: 56.5217391%; }

	&__box-content {
		padding: $p_vert-box-content 20px;

		@include md-tablet {
			text-align: center; }

		@include md-mobile {
			padding: $p_vert-box-content 20px ( $p_vert-box-content + 5px ); } }

	&__title {
		margin: -6px 0 9px;

		color: $black;
		font-size: 22px;
		line-height: 28px;
		font-weight: 700;

		@include md-desk_sm {
			margin: -6px 0 9px;
			font-size: 20px;
			line-height: 26px; }

		@include md-tablet {
			margin: -5px 0 9px;
			font-size: 18px;
			line-height: 24px; }

		&_last {
			margin-bottom: -8px;

			@include md-desk_sm {
				margin-bottom: -8px; }

			@include md-tablet {
				margin-bottom: -6px; } } }

	&__text {
		margin: -4px 0 -6px;

		color: $black;
		font-size: 16px;
		line-height: 22px;

		@include md-tablet {
			margin: -5px 0 -6px;
			font-size: 15px;
			line-height: 22px; } } }
