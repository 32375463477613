.b-subscribe {
	margin-bottom: 70px;

	&_last {
		margin-bottom: 0; }

	&__form {
		display: flex; // to collapse margins inside
		flex-direction: column;

		@include md-tablet_sm {
			max-width: 350px;
			margin: 0 auto; } }

	&__form-inner {
		display: flex;
		margin: -$p_horiz-col;

		@include md-tablet_sm {
			flex-wrap: wrap; } }

	&__form-item {
		max-width: 100%;
		margin: $p_horiz-col;

		&_input {
			width: 255px;

			@include md-tablet_sm {
				width: 100%; } }

		&_btn {
			width: 350px;

			@include md-tablet_sm {
				width: 100%; } } }

	&__form-input {

		@include md-tablet_sm {
			text-align: center; } } }
