/*----------- main -----------*/
/*----------- main (end) -----------*/
/*----------- typography -----------*/
/*----------- typography (end) -----------*/
/*----------- common -----------*/
/*----------- common (end) -----------*/
/*----------- link -----------*/
/*----------- link (end) -----------*/
/*----------- form -----------*/
/*----------- form (end) -----------*/
/*----------- table -----------*/
/*----------- table (end) -----------*/
/*----------- button -----------*/
/*----------- button (end) -----------*/
/*----------- images -----------*/
/*----------- images (end) -----------*/
/*----------- fonts -----------*/
/*----------- fonts (end) -----------*/
/*----------- usage -----------*/
/*----------- usage (end) -----------*/
/*----------- typography -----------*/
/*----------- typography (end) -----------*/
/*----------- common -----------*/
/*----------- common (end) -----------*/
/*----------- media -----------*/
/*----------- media (end) -----------*/
/*----------- link -----------*/
/*----------- link (end) -----------*/
/*----------- form -----------*/
/*----------- form (end) -----------*/
/*----------- table -----------*/
/*----------- table (end) -----------*/
/*----------- button -----------*/
/*----------- button (end) -----------*/
/* settings */
/*----------- main -----------*/
/*----------- main (end) -----------*/
/*----------- typography -----------*/
/*----------- typography (end) -----------*/
/*----------- common -----------*/
/*----------- common (end) -----------*/
/*----------- link -----------*/
/*----------- link (end) -----------*/
/*----------- form -----------*/
/*----------- form (end) -----------*/
/*----------- table -----------*/
/*----------- table (end) -----------*/
/*----------- button -----------*/
/*----------- button (end) -----------*/
/*----------- images -----------*/
/*----------- images (end) -----------*/
/*----------- fonts -----------*/
/*----------- fonts (end) -----------*/
/*----------- usage -----------*/
/*----------- usage (end) -----------*/
/*----------- typography -----------*/
/*----------- typography (end) -----------*/
/*----------- common -----------*/
/*----------- common (end) -----------*/
/*----------- media -----------*/
/*----------- media (end) -----------*/
/*----------- link -----------*/
/*----------- link (end) -----------*/
/*----------- form -----------*/
/*----------- form (end) -----------*/
/*----------- table -----------*/
/*----------- table (end) -----------*/
/*----------- button -----------*/
/*----------- button (end) -----------*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap");
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/sprite/icomoon.eot?5fqaym");
  src: url("../fonts/sprite/icomoon.eot?5fqaym#iefix") format("embedded-opentype"), url("../fonts/sprite/icomoon.ttf?5fqaym") format("truetype"), url("../fonts/sprite/icomoon.woff?5fqaym") format("woff"), url("../fonts/sprite/icomoon.svg?5fqaym#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

*:root {
  /*----------- colors -----------*/
  /*----------- main -----------*/
  --black:					$black;
  --grey-darker:				$grey-darker;
  --grey-dark:				$grey-dark;
  --grey:						$grey;
  --grey-light:				$grey-light;
  --grey-lighter:				$grey-lighter;
  --white:					$white;
  --brand-primary:			$brand-primary;
  --brand-success:			$brand-success;
  --brand-info:				$brand-info;
  --brand-warning:			$brand-warning;
  --brand-danger:				$brand-danger;
  /*----------- main (end) -----------*/
  /*----------- common -----------*/
  --bg_c-body:				$bg_c-body;
  --brd_c:					$brd_c;
  /*----------- common (end) -----------*/
  /*----------- colors (end) -----------*/
  /*----------- options -----------*/
  /*----------- media -----------*/
  --md-desk_md:				$md-desk_md;
  --md-desk_sm:				$md-desk_sm;
  --md-tablet:				$md-tablet;
  --md-mobile:				$md-mobile;
  --md-mobile_sm:				$md-mobile_sm;
  /*----------- media (end) -----------*/
  /*----------- options (end) -----------*/ }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
hr,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
button,
fieldset, form, label, legend, textarea, input, select,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-smoothing: antialiased; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

iframe, svg {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote::before, blockquote::after,
q::before, q::after {
  content: '';
  content: none; }

img,
video {
  display: block;
  max-width: 100%; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

th {
  text-align: left; }

*:focus {
  outline: 0; }

ins {
  text-decoration: none; }

button,
input,
textarea,
select,
a {
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
a {
  letter-spacing: inherit;
  word-spacing: inherit;
  cursor: pointer; }

button {
  display: block;
  overflow: visible;
  color: inherit; }

button,
input[type='submit'],
input[type='reset'],
input[type='file'] {
  text-align: left; }

input {
  word-break: normal; }
  input[type="checkbox"], input[type="radio"] {
    cursor: pointer; }
  input[type="color"] {
    border: none;
    outline: none;
    -webkit-appearance: none; }
    input[type="color"]::-webkit-color-swatch-wrapper {
      padding: 0; }
    input[type="color"]::-moz-color-swatch-wrapper {
      padding: 0; }
    input[type="color"]::-webkit-color-swatch {
      border: none; }
    input[type="color"]::-moz-color-swatch {
      border: none; }

input,
textarea,
select {
  display: block;
  -moz-appearance: none;
  -webkit-appearance: none; }

label {
  cursor: pointer; }

a {
  color: inherit;
  text-decoration: inherit; }

mark {
  background-color: transparent;
  color: inherit; }

pre {
  white-space: pre-wrap; }

@-moz-document url-prefix() {
  button::-moz-focus-inner,
  input[type="reset"]::-moz-focus-inner,
  input[type="button"]::-moz-focus-inner,
  input[type="submit"]::-moz-focus-inner,
  input[type="file"] > input[type="button"]::-moz-focus-inner {
    margin: 0;
    border: 0;
    padding: 0; } }

*::selection {
  background: #50F6D9;
  color: #fff; }

* {
  box-sizing: border-box; }

*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  font-variant-ligatures: no-common-ligatures !important;
  font-size: 10px;
  text-size-adjust: 100%;
  line-height: 1.2;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  word-wrap: break-word;
  word-break: normal;
  word-break: break-word; }

body {
  position: relative;
  min-width: 320px;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: visible;
  background-color: #fff;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  line-height: 1.35;
  font-weight: 400;
  -webkit-overflow-scrolling: touch; }
  @media only screen and (max-width: 640px) {
    body {
      min-height: auto; } }
  body._no_scroll {
    height: 100%;
    overflow: hidden; }
  body._mobile_test {
    width: 320px; }

.-ol-container {
  position: relative;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 30px; }
  @media only screen and (max-width: 1024px) {
    .-ol-container {
      width: 100%;
      max-width: none;
      padding: 0 20px; } }
  .-ol-container_full_w {
    width: 100%;
    max-width: none;
    padding: 0 30px; }
    @media only screen and (max-width: 1024px) {
      .-ol-container_full_w {
        padding: 0 20px; } }

.-ol-clfx::before, .-ol-clfx::after {
  content: "";
  display: table; }

.-ol-clfx::after {
  clear: both; }

.-ol-row,
.-ol-row-float,
.-ol-row-inline,
.-ol-row-table {
  margin-left: -15px;
  margin-right: -15px; }
  @media only screen and (max-width: 1024px) {
    .-ol-row,
    .-ol-row-float,
    .-ol-row-inline,
    .-ol-row-table {
      margin-left: -10px;
      margin-right: -10px; } }

.-ol-row {
  position: relative;
  display: flex;
  flex-wrap: wrap; }

.-ol-row-inline {
  position: relative;
  font-size: 0 !important;
  letter-spacing: 0 !important; }
  .-ol-row-inline > .-ol-col {
    display: inline-block;
    vertical-align: top;
    font-size: 1.6rem;
    letter-spacing: 0.06em; }
  .-ol-row-inline > .-ol-col_recovery {
    font-size: 1.6rem;
    letter-spacing: 0.06em; }

.-ol-row-table {
  position: relative;
  display: table;
  height: 100%;
  width: calc( 100% + 30px);
  table-layout: fixed; }
  .-ol-row-table > .-ol-col_table {
    display: table-cell;
    vertical-align: top; }
  .-ol-row-table > .-ol-col_table_major {
    width: 100%; }

.-ol-row-float {
  position: relative; }
  .-ol-row-float::before, .-ol-row-float::after {
    content: "";
    display: table; }
  .-ol-row-float::after {
    clear: both; }
  .-ol-row-float > [class*='-ol-col'] {
    float: left; }

.-ol-row_without_inside_space {
  margin: 0; }

[class*=-ol-col-] {
  position: relative;
  vertical-align: top;
  padding: 0 15px; }
  @media only screen and (max-width: 1024px) {
    [class*=-ol-col-] {
      width: 100%;
      padding: 0 10px; } }

.-ol-col_without_inside_space {
  padding: 0; }

.-ol-col_valign_m {
  vertical-align: middle !important; }

.-ol-col_valign_b {
  vertical-align: bottom !important; }

[class*=-ol-col-offset] {
  margin-left: 0; }

@media only screen and (min-width: 1025px) {
  .-ol-col-0 {
    width: 0%; }
  .-ol-col-offset-0 {
    margin-left: 0%; } }

@media only screen and (min-width: 1025px) {
  .-ol-col-1 {
    width: 8.33333%; }
  .-ol-col-offset-1 {
    margin-left: 8.33333%; } }

@media only screen and (min-width: 1025px) {
  .-ol-col-2 {
    width: 16.66667%; }
  .-ol-col-offset-2 {
    margin-left: 16.66667%; } }

@media only screen and (min-width: 1025px) {
  .-ol-col-3 {
    width: 25%; }
  .-ol-col-offset-3 {
    margin-left: 25%; } }

@media only screen and (min-width: 1025px) {
  .-ol-col-4 {
    width: 33.33333%; }
  .-ol-col-offset-4 {
    margin-left: 33.33333%; } }

@media only screen and (min-width: 1025px) {
  .-ol-col-5 {
    width: 41.66667%; }
  .-ol-col-offset-5 {
    margin-left: 41.66667%; } }

@media only screen and (min-width: 1025px) {
  .-ol-col-6 {
    width: 50%; }
  .-ol-col-offset-6 {
    margin-left: 50%; } }

@media only screen and (min-width: 1025px) {
  .-ol-col-7 {
    width: 58.33333%; }
  .-ol-col-offset-7 {
    margin-left: 58.33333%; } }

@media only screen and (min-width: 1025px) {
  .-ol-col-8 {
    width: 66.66667%; }
  .-ol-col-offset-8 {
    margin-left: 66.66667%; } }

@media only screen and (min-width: 1025px) {
  .-ol-col-9 {
    width: 75%; }
  .-ol-col-offset-9 {
    margin-left: 75%; } }

@media only screen and (min-width: 1025px) {
  .-ol-col-10 {
    width: 83.33333%; }
  .-ol-col-offset-10 {
    margin-left: 83.33333%; } }

@media only screen and (min-width: 1025px) {
  .-ol-col-11 {
    width: 91.66667%; }
  .-ol-col-offset-11 {
    margin-left: 91.66667%; } }

@media only screen and (min-width: 1025px) {
  .-ol-col-12 {
    width: 100%; }
  .-ol-col-offset-12 {
    margin-left: 100%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-0 {
    width: 0%; }
  .-ol-col-dm-offset-0 {
    margin-left: 0%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-1 {
    width: 8.33333%; }
  .-ol-col-dm-offset-1 {
    margin-left: 8.33333%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-2 {
    width: 16.66667%; }
  .-ol-col-dm-offset-2 {
    margin-left: 16.66667%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-3 {
    width: 25%; }
  .-ol-col-dm-offset-3 {
    margin-left: 25%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-4 {
    width: 33.33333%; }
  .-ol-col-dm-offset-4 {
    margin-left: 33.33333%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-5 {
    width: 41.66667%; }
  .-ol-col-dm-offset-5 {
    margin-left: 41.66667%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-6 {
    width: 50%; }
  .-ol-col-dm-offset-6 {
    margin-left: 50%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-7 {
    width: 58.33333%; }
  .-ol-col-dm-offset-7 {
    margin-left: 58.33333%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-8 {
    width: 66.66667%; }
  .-ol-col-dm-offset-8 {
    margin-left: 66.66667%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-9 {
    width: 75%; }
  .-ol-col-dm-offset-9 {
    margin-left: 75%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-10 {
    width: 83.33333%; }
  .-ol-col-dm-offset-10 {
    margin-left: 83.33333%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-11 {
    width: 91.66667%; }
  .-ol-col-dm-offset-11 {
    margin-left: 91.66667%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-12 {
    width: 100%; }
  .-ol-col-dm-offset-12 {
    margin-left: 100%; } }

@media only screen and (max-width: 1024px) {
  .-ol-col-tb-0 {
    width: 0%; }
  .-ol-col-tb-offset-0 {
    margin-left: 0%; } }

@media only screen and (max-width: 1024px) {
  .-ol-col-tb-1 {
    width: 8.33333%; }
  .-ol-col-tb-offset-1 {
    margin-left: 8.33333%; } }

@media only screen and (max-width: 1024px) {
  .-ol-col-tb-2 {
    width: 16.66667%; }
  .-ol-col-tb-offset-2 {
    margin-left: 16.66667%; } }

@media only screen and (max-width: 1024px) {
  .-ol-col-tb-3 {
    width: 25%; }
  .-ol-col-tb-offset-3 {
    margin-left: 25%; } }

@media only screen and (max-width: 1024px) {
  .-ol-col-tb-4 {
    width: 33.33333%; }
  .-ol-col-tb-offset-4 {
    margin-left: 33.33333%; } }

@media only screen and (max-width: 1024px) {
  .-ol-col-tb-5 {
    width: 41.66667%; }
  .-ol-col-tb-offset-5 {
    margin-left: 41.66667%; } }

@media only screen and (max-width: 1024px) {
  .-ol-col-tb-6 {
    width: 50%; }
  .-ol-col-tb-offset-6 {
    margin-left: 50%; } }

@media only screen and (max-width: 1024px) {
  .-ol-col-tb-7 {
    width: 58.33333%; }
  .-ol-col-tb-offset-7 {
    margin-left: 58.33333%; } }

@media only screen and (max-width: 1024px) {
  .-ol-col-tb-8 {
    width: 66.66667%; }
  .-ol-col-tb-offset-8 {
    margin-left: 66.66667%; } }

@media only screen and (max-width: 1024px) {
  .-ol-col-tb-9 {
    width: 75%; }
  .-ol-col-tb-offset-9 {
    margin-left: 75%; } }

@media only screen and (max-width: 1024px) {
  .-ol-col-tb-10 {
    width: 83.33333%; }
  .-ol-col-tb-offset-10 {
    margin-left: 83.33333%; } }

@media only screen and (max-width: 1024px) {
  .-ol-col-tb-11 {
    width: 91.66667%; }
  .-ol-col-tb-offset-11 {
    margin-left: 91.66667%; } }

@media only screen and (max-width: 1024px) {
  .-ol-col-tb-12 {
    width: 100%; }
  .-ol-col-tb-offset-12 {
    margin-left: 100%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-0 {
    width: 0%; }
  .-ol-col-mb-offset-0 {
    margin-left: 0%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-1 {
    width: 8.33333%; }
  .-ol-col-mb-offset-1 {
    margin-left: 8.33333%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-2 {
    width: 16.66667%; }
  .-ol-col-mb-offset-2 {
    margin-left: 16.66667%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-3 {
    width: 25%; }
  .-ol-col-mb-offset-3 {
    margin-left: 25%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-4 {
    width: 33.33333%; }
  .-ol-col-mb-offset-4 {
    margin-left: 33.33333%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-5 {
    width: 41.66667%; }
  .-ol-col-mb-offset-5 {
    margin-left: 41.66667%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-6 {
    width: 50%; }
  .-ol-col-mb-offset-6 {
    margin-left: 50%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-7 {
    width: 58.33333%; }
  .-ol-col-mb-offset-7 {
    margin-left: 58.33333%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-8 {
    width: 66.66667%; }
  .-ol-col-mb-offset-8 {
    margin-left: 66.66667%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-9 {
    width: 75%; }
  .-ol-col-mb-offset-9 {
    margin-left: 75%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-10 {
    width: 83.33333%; }
  .-ol-col-mb-offset-10 {
    margin-left: 83.33333%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-11 {
    width: 91.66667%; }
  .-ol-col-mb-offset-11 {
    margin-left: 91.66667%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-12 {
    width: 100%; }
  .-ol-col-mb-offset-12 {
    margin-left: 100%; } }

.-ol-gap-remover {
  margin-left: -15px;
  margin-right: -15px; }
  @media only screen and (max-width: 1024px) {
    .-ol-gap-remover {
      margin: 0; } }

@media only screen and (max-width: 1024px) {
  .-ol-gap-remover-tb {
    margin-left: -10px;
    margin-right: -10px; } }

@media only screen and (max-width: 640px) {
  .-ol-gap-remover-mb {
    margin-left: -10px;
    margin-right: -10px; } }

._pos_relative {
  position: relative !important; }

._pos_absolute {
  position: absolute !important; }

._block {
  display: block !important; }

@media only screen and (max-width: 1024px) {
  ._block_tb {
    display: block !important; } }

@media only screen and (max-width: 640px) {
  ._block_mb {
    display: block !important; } }

._flex {
  display: flex !important; }

@media only screen and (max-width: 1024px) {
  ._flex_tb {
    display: flex !important; } }

@media only screen and (max-width: 640px) {
  ._flex_mb {
    display: flex !important; } }

._inline_block {
  display: inline-block !important; }

@media only screen and (max-width: 1024px) {
  ._inline_block_tb {
    display: inline-block !important; } }

@media only screen and (max-width: 640px) {
  ._inline_block_mb {
    display: inline-block !important; } }

._inline {
  display: inline !important; }

@media only screen and (max-width: 1024px) {
  ._inline_tb {
    display: inline !important; } }

@media only screen and (max-width: 640px) {
  ._inline_mb {
    display: inline !important; } }

._only_tb {
  display: none !important; }
  @media only screen and (max-width: 1024px) {
    ._only_tb {
      display: block !important; } }

._only_inline_block_tb {
  display: none !important; }
  @media only screen and (max-width: 1024px) {
    ._only_inline_block_tb {
      display: inline-block !important; } }

._only_flex_tb {
  display: none !important; }
  @media only screen and (max-width: 1024px) {
    ._only_flex_tb {
      display: flex !important; } }

._only_inline_tb {
  display: none !important; }
  @media only screen and (max-width: 1024px) {
    ._only_inline_tb {
      display: inline !important; } }

._only_mb {
  display: none !important; }
  @media only screen and (max-width: 640px) {
    ._only_mb {
      display: block !important; } }

._only_inline_block_mb {
  display: none !important; }
  @media only screen and (max-width: 640px) {
    ._only_inline_block_mb {
      display: inline-block !important; } }

._only_flex_mb {
  display: none !important; }
  @media only screen and (max-width: 640px) {
    ._only_flex_mb {
      display: flex !important; } }

._only_inline_mb {
  display: none !important; }
  @media only screen and (max-width: 640px) {
    ._only_inline_mb {
      display: inline !important; } }

._none {
  display: none !important; }

@media only screen and (max-width: 1024px) {
  ._none_tb {
    display: none !important; } }

@media only screen and (max-width: 640px) {
  ._none_mb {
    display: none !important; } }

._bind {
  position: relative !important; }

._unbind {
  position: static !important; }

._flex_horiz_center {
  justify-content: center; }

._full_w {
  width: 100% !important; }

._full_h {
  height: 100% !important; }

._full_h_vh {
  height: 100vh !important; }

._min_h_auto {
  min-height: auto !important; }

._min_h_full_vh {
  min-height: 100vh !important; }

._hidden {
  overflow: hidden !important; }

._overflow_auto {
  overflow: auto !important; }

._block_center {
  margin-left: auto !important;
  margin-right: auto !important; }

._block_vert_center {
  margin-top: auto !important;
  margin-bottom: auto !important; }

._letter_spacing_inherit {
  letter-spacing: inherit; }

._text_center {
  text-align: center !important; }

._text_left {
  text-align: left !important; }

._text_right {
  text-align: right !important; }

._text_capitalize {
  text-transform: capitalize !important; }

._text_uppercase {
  text-transform: uppercase !important; }

._text_ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal; }

._nowrap {
  white-space: nowrap; }

._cur_p {
  cursor: pointer !important; }

._events_none {
  pointer-events: none !important; }

/* mixins */
/* base */
.-ol-btn {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  border-style: solid;
  background-image: none;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  touch-action: manipulation;
  transition: all .2s;
  height: auto;
  min-height: auto;
  border-radius: 0;
  border-width: 0;
  padding: 18px 25px;
  font-size: 15px;
  line-height: 25px;
  position: relative;
  background-color: #000;
  color: #fff;
  transition: color .2s; }
  .-ol-btn:hover {
    color: #000; }
  .-ol-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #50F6D9;
    transition: width .2s; }
  .-ol-btn:hover::before {
    width: 100%; }
  .-ol-btn_default {
    border-color: #fff;
    background: #fff;
    color: #50F6D9;
    transition: all .2s; }
    .-ol-btn_default:hover {
      border-color: #f2f2f2;
      background: #f2f2f2;
      color: #50F6D9; }
    .-ol-btn_default:active {
      border-color: #e6e6e6;
      background: #e6e6e6;
      color: #50F6D9; }
  .-ol-btn_success {
    border-color: #58c067;
    background: #58c067;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_success:hover {
      border-color: #46b956;
      background: #46b956;
      color: #fff; }
    .-ol-btn_success:active {
      border-color: #3fa64e;
      background: #3fa64e;
      color: #fff; }
  .-ol-btn_info {
    border-color: #69c1df;
    background: #69c1df;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_info:hover {
      border-color: #54b8db;
      background: #54b8db;
      color: #fff; }
    .-ol-btn_info:active {
      border-color: #3fb0d6;
      background: #3fb0d6;
      color: #fff; }
  .-ol-btn_warning {
    border-color: #f5bf53;
    background: #f5bf53;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_warning:hover {
      border-color: #f4b63b;
      background: #f4b63b;
      color: #fff; }
    .-ol-btn_warning:active {
      border-color: #f2ad23;
      background: #f2ad23;
      color: #fff; }
  .-ol-btn_danger {
    border-color: #ed6161;
    background: #ed6161;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_danger:hover {
      border-color: #ea4a4a;
      background: #ea4a4a;
      color: #fff; }
    .-ol-btn_danger:active {
      border-color: #e83333;
      background: #e83333;
      color: #fff; }
  .-ol-btn_outline {
    border-color: #50F6D9;
    background: transparent;
    color: #50F6D9;
    transition: all .2s; }
    .-ol-btn_outline:hover {
      border-color: #38f5d4;
      background: rgba(0, 0, 0, 0);
      color: #50F6D9; }
    .-ol-btn_outline:active {
      border-color: #1ff4ce;
      background: rgba(0, 0, 0, 0);
      color: #50F6D9; }
  .-ol-btn[disabled] {
    border-color: #cccccc;
    background: #cccccc;
    color: #fff;
    transition: all .2s;
    cursor: not-allowed;
    pointer-events: none; }
    .-ol-btn[disabled]:hover {
      border-color: #cccccc;
      background: #cccccc;
      color: #fff; }
    .-ol-btn[disabled]:active {
      border-color: #cccccc;
      background: #cccccc;
      color: #fff; }
  .-ol-btn_link {
    width: auto;
    height: auto;
    border: 0;
    border-radius: 0;
    padding: 0;
    border-color: transparent;
    border-bottom: 1px solid transparent;
    color: #50F6D9; }
    .-ol-btn_link:hover {
      border-bottom-color: fadeout(#50F6D9, 20%);
      color: #50F6D9; }
  .-ol-btn_lg {
    height: auto;
    min-height: auto;
    border-radius: 0;
    border-width: 0;
    padding: 10px 30px;
    font-size: 2rem;
    line-height: 30px; }
  .-ol-btn_sm {
    height: auto;
    min-height: auto;
    border-radius: 0;
    border-width: 0;
    padding: 7.5px 20px;
    font-size: 1.4rem;
    line-height: 21px; }
  .-ol-btn_xs {
    height: auto;
    min-height: auto;
    border-radius: 0;
    border-width: 0;
    padding: 5.5px 15px;
    font-size: 1.4rem;
    line-height: 21px; }
  .-ol-btn_w_fix_lg {
    width: 350px;
    max-width: 100%; }
  .-ol-btn_min_w_initial {
    min-width: 0 !important; }

.-ol-input,
.-ol-textarea {
  display: block;
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid #000;
  border-radius: 0;
  background: #fff;
  color: #000;
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-overflow: ellipsis; }
  .-ol-input::-webkit-input-placeholder,
  .-ol-textarea::-webkit-input-placeholder {
    color: #000;
    font-weight: inherit;
    letter-spacing: 0.02em; }
  .-ol-input::-moz-placeholder,
  .-ol-textarea::-moz-placeholder {
    color: #000;
    font-weight: inherit;
    letter-spacing: 0.02em; }
  .-ol-input:-moz-placeholder,
  .-ol-textarea:-moz-placeholder {
    color: #000;
    font-weight: inherit;
    letter-spacing: 0.02em; }
  .-ol-input:-ms-input-placeholder,
  .-ol-textarea:-ms-input-placeholder {
    color: #000;
    font-weight: inherit;
    letter-spacing: 0.02em; }
  .-ol-input:hover::-webkit-input-placeholder,
  .-ol-textarea:hover::-webkit-input-placeholder {
    color: #000;
    font-weight: inherit;
    letter-spacing: 0.02em; }
  .-ol-input:hover::-moz-placeholder,
  .-ol-textarea:hover::-moz-placeholder {
    color: #000;
    font-weight: inherit;
    letter-spacing: 0.02em; }
  .-ol-input:hover:-moz-placeholder,
  .-ol-textarea:hover:-moz-placeholder {
    color: #000;
    font-weight: inherit;
    letter-spacing: 0.02em; }
  .-ol-input:hover:-ms-input-placeholder,
  .-ol-textarea:hover:-ms-input-placeholder {
    color: #000;
    font-weight: inherit;
    letter-spacing: 0.02em; }
  .-ol-input:focus::-webkit-input-placeholder,
  .-ol-textarea:focus::-webkit-input-placeholder {
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.02em; }
  .-ol-input:focus::-moz-placeholder,
  .-ol-textarea:focus::-moz-placeholder {
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.02em; }
  .-ol-input:focus:-moz-placeholder,
  .-ol-textarea:focus:-moz-placeholder {
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.02em; }
  .-ol-input:focus:-ms-input-placeholder,
  .-ol-textarea:focus:-ms-input-placeholder {
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.02em; }
  .-ol-input[readonly], .-ol-input[disabled],
  .-ol-textarea[readonly],
  .-ol-textarea[disabled] {
    color: #d9d9d9;
    cursor: default; }
  .-ol-input:focus ~ .-ol-input-line,
  .-ol-textarea:focus ~ .-ol-input-line {
    width: 100%; }

.-ol-input {
  height: 61px; }

.-ol-textarea {
  height: 152px;
  resize: vertical;
  overflow: auto; }

.-ol-input-line {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #50F6D9;
  transition: width .2s; }

.-ol-input-file {
  position: relative;
  overflow: hidden;
  width: 100%; }
  .-ol-input-file input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    opacity: 0;
    cursor: pointer; }

.-ol-checkbox {
  position: relative;
  width: 14px;
  height: 14px; }
  .-ol-checkbox__input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    overflow: hidden;
    width: 100%;
    height: 100%; }
  .-ol-checkbox__trigger {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #f2f2f2;
    border-radius: 2px;
    background-color: #fff;
    cursor: pointer; }
  .-ol-checkbox__input:checked ~ .-ol-checkbox__trigger {
    border: 5px solid #50F6D9;
    background-color: #fff; }
  .-ol-checkbox[disabled] {
    opacity: .55;
    cursor: default; }

.-ol-radio {
  position: relative;
  width: 14px;
  height: 14px; }
  .-ol-radio__input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    overflow: hidden;
    width: 100%;
    height: 100%; }
    .-ol-radio__input[disabled] {
      cursor: default; }
  .-ol-radio__trigger {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #f2f2f2;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer; }
  .-ol-radio__input:checked ~ .-ol-radio__trigger {
    border: 5px solid #50F6D9;
    background-color: #fff; }
  .-ol-radio__input[disabled] ~ .-ol-radio__trigger {
    border-color: #f2f2f2;
    background-color: #f2f2f2; }

.-ol-label {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; }
  .-ol-label_last {
    margin-bottom: 0; }

.-ol-label-text {
  display: block;
  color: #000;
  font-size: 1.6rem;
  font-weight: 700; }

.-ol-input-wrap {
  position: relative; }
  .-ol-input-wrap_disabled {
    opacity: .6; }
    .-ol-input-wrap_disabled .-ol-input,
    .-ol-input-wrap_disabled .-ol-textarea {
      color: #000; }
    .-ol-input-wrap_disabled label {
      cursor: auto; }
  .-ol-input-wrap_error .-ol-input,
  .-ol-input-wrap_error .-ol-textarea {
    border-color: #ed6161;
    color: #ed6161; }

.-ol-row-input {
  margin-bottom: 30px; }
  @media only screen and (max-width: 640px) {
    .-ol-row-input {
      margin-bottom: 25px; } }
  .-ol-row-input_last {
    margin-bottom: 0; }

@media only screen and (max-width: 640px) {
  .-ol-row-input-mb {
    margin-bottom: 25px; } }

.-ol-form-body {
  margin-bottom: 60px; }
  .-ol-form-body_last {
    margin-bottom: 0; }

.-ol-content h1,
.-ol-h1,
.-ol-content h2,
.-ol-h2,
.-ol-content h3,
.-ol-h3,
.-ol-content h4,
.-ol-h4,
.-ol-content h5,
.-ol-h5,
.-ol-content h6,
.-ol-h6 {
  color: #000;
  line-height: 1.35;
  font-weight: 400; }

.-ol-content h1,
.-ol-h1 {
  margin-bottom: 20px;
  font-size: 3.6rem; }

.-ol-content h2,
.-ol-h2 {
  margin-bottom: 15px;
  font-size: 3rem; }

.-ol-content h3,
.-ol-h3 {
  margin-bottom: 10px;
  font-size: 2.4rem; }

.-ol-content h4,
.-ol-h4 {
  margin-bottom: 7px;
  font-size: 1.8rem; }

.-ol-content h5,
.-ol-h5 {
  margin-bottom: 5px;
  font-size: 1.6rem; }

.-ol-content h6,
.-ol-h6 {
  margin-bottom: 5px;
  font-size: 1.2rem; }

.-ol-h-none {
  margin-bottom: 0;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  line-height: 1.35;
  letter-spacing: 0.06em;
  font-weight: 400;
  word-spacing: 0;
  text-transform: none; }

.-ol-content a,
.-ol-link {
  display: inline;
  border-bottom: 1px solid transparent;
  color: #50F6D9;
  line-height: inherit;
  letter-spacing: inherit;
  cursor: pointer;
  transition: all .2s; }
  .-ol-content a:hover,
  .-ol-link:hover {
    border-bottom-color: #1ff4ce;
    color: #1ff4ce; }

.-ol-content b,
.-ol-b,
.-ol-content strong,
.-ol-strong {
  font-weight: bold;
  letter-spacing: inherit; }

.-ol-content i,
.-ol-i,
.-ol-content em,
.-ol-em {
  font-style: italic;
  letter-spacing: inherit; }

.-ol-content sub,
.-ol-sub,
.-ol-content sup,
.-ol-sup {
  font-size: .6em; }

.-ol-content sub,
.-ol-sub {
  vertical-align: bottom; }

.-ol-content sup,
.-ol-sup {
  vertical-align: top; }

.i-menu::after, .b-header__nav-hamburger-btn::after,
.i-cross::after,
.b-header__nav-popup-cross::after,
.i-arrow::after,
.b-breadcrumbs__item:not(.b-breadcrumbs__item_active)::after,
.i-logo::after,
.b-footer::after,
.b-header__logo::after,
.i-price::after,
.b-advantages__list-item_price::after,
.i-point::after,
.b-advantages__list-item_location::after,
.i-star::after,
.b-advantages__list-item_favorite::after,
.i-instagram::after,
.b-footer__social-list-item_int::after,
.i-facebook::after,
.b-footer__social-list-item_fb::after,
.i-magnifier::after,
.b-gallery__box::after {
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  letter-spacing: 0;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga'; }

.i-menu::after, .b-header__nav-hamburger-btn::after {
  content: '\e908'; }


.i-cross::after,
.b-header__nav-popup-cross::after {
  content: '\e909'; }


.i-arrow::after,
.b-breadcrumbs__item:not(.b-breadcrumbs__item_active)::after {
  content: '\e907'; }


.i-logo::after,
.b-footer::after,
.b-header__logo::after {
  content: '\e900'; }


.i-price::after,
.b-advantages__list-item_price::after {
  content: '\e901'; }


.i-point::after,
.b-advantages__list-item_location::after {
  content: '\e902'; }


.i-star::after,
.b-advantages__list-item_favorite::after {
  content: '\e903'; }


.i-instagram::after,
.b-footer__social-list-item_int::after {
  content: '\e904'; }


.i-facebook::after,
.b-footer__social-list-item_fb::after {
  content: '\e905'; }


.i-magnifier::after,
.b-gallery__box::after {
  content: '\e906'; }

/* snippets */
.-ol-container_sm {
  max-width: 980px; }
  @media only screen and (max-width: 1024px) {
    .-ol-container_sm {
      max-width: 960px;
      width: 100%; } }

.-ol-container_xs {
  max-width: 730px; }
  @media only screen and (max-width: 1024px) {
    .-ol-container_xs {
      max-width: 960px;
      width: 100%; } }

._z_i_1 {
  z-index: 1 !important; }

/* project */
.l-cell {
  display: table;
  height: 100%;
  width: 100%;
  table-layout: fixed; }
  .l-cell__item {
    display: table-cell;
    vertical-align: top; }
  .l-cell__item_major {
    width: 100%; }
  .l-cell__item_middle {
    vertical-align: middle; }
  @media only screen and (max-width: 640px) {
    .l-cell__item_top_mb {
      vertical-align: top; } }

.l-content-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 53px;
  transition: 0.2s filter; }
  ._modal_active .l-content-wrapper {
    filter: blur(3px); }
  .l-content-wrapper__item_major {
    flex-grow: 1;
    width: 100%;
    max-width: 1740px;
    margin: 0 auto; }
  .l-content-wrapper__item_shrink_none {
    flex-shrink: 0; }

.l-flex {
  display: flex;
  flex-wrap: wrap; }
  .l-flex_nowrap {
    flex-wrap: nowrap; }
  .l-flex_valign_center {
    align-items: center; }
  @media only screen and (max-width: 640px) {
    .l-flex_valign_center_mb {
      align-items: center; } }
  .l-flex_valign_center_top_mb {
    align-items: center; }
    @media only screen and (max-width: 640px) {
      .l-flex_valign_center_top_mb {
        align-items: flex-start; } }
  .l-flex_right {
    justify-content: flex-end; }
  .l-flex_center {
    justify-content: center; }
  .l-flex_space_between {
    justify-content: space-between; }
  .l-flex_space_around {
    justify-content: space-around; }
  .l-flex_col {
    flex-direction: column; }
  .l-flex > .l-flex__item_stretch {
    flex-grow: 1; }
  .l-flex > .l-flex__item_major {
    flex-shrink: 0; }
  @media only screen and (max-width: 1024px) {
    .l-flex > .l-flex__item_equal_none_tb {
      flex: 0 1 auto; } }
  .l-flex_equal {
    flex-wrap: nowrap; }
  .l-flex_equal > .l-flex__item {
    flex: 1 1 100%;
    overflow: hidden; }
  .l-flex_equal_with_width > .l-flex__item {
    flex-grow: 1;
    flex-basis: 0;
    width: 0; }

.l-indent {
  margin: -10px -15px; }
  .l-indent_sm {
    margin: -5px -10px; }
  .l-indent__item {
    padding: 10px 15px; }
  .l-indent_sm .l-indent__item {
    padding: 5px 10px; }

.l-inline {
  font-size: 0 !important;
  letter-spacing: 0 !important; }
  .l-inline__item {
    display: inline-block;
    vertical-align: top;
    font-size: 1.6rem;
    letter-spacing: 0.06em; }
  .l-inline__item_recovery {
    font-size: 1.6rem;
    letter-spacing: 0.06em; }

.l-justify {
  font-size: 0 !important;
  letter-spacing: 0 !important;
  text-align: justify;
  text-align-last: justify;
  text-justify: newspaper; }
  .l-justify::after {
    content: '';
    display: inline-block;
    width: 100%;
    visibility: hidden;
    overflow: hidden; }
  .l-justify__item {
    display: inline-block;
    vertical-align: top;
    font-size: 1.6rem;
    letter-spacing: 0.06em;
    text-align: left;
    text-align-last: auto;
    text-justify: auto; }
  .l-justify__item_recovery {
    font-size: 1.6rem;
    letter-spacing: 0.06em;
    text-align: left;
    text-align-last: auto;
    text-justify: auto; }

.l-row {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%; }
  .l-row__item {
    display: table-row; }
  .l-row__item_major {
    height: 100%; }

.b-advantages {
  margin-bottom: 100px; }
  @media only screen and (max-width: 1024px) {
    .b-advantages {
      margin-bottom: 80px; } }
  @media only screen and (max-width: 640px) {
    .b-advantages {
      margin-bottom: 70px;
      text-align: center; } }
  .b-advantages_last {
    margin-bottom: 0; }
  .b-advantages__list-item {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    padding-left: 190px; }
    @media only screen and (max-width: 1024px) {
      .b-advantages__list-item {
        padding-left: 130px; } }
    @media only screen and (max-width: 768px) {
      .b-advantages__list-item {
        margin-bottom: 45px;
        padding-left: 85px; } }
    @media only screen and (max-width: 640px) {
      .b-advantages__list-item {
        margin-bottom: 40px;
        padding-top: 80px;
        padding-left: 0; } }
    .b-advantages__list-item:before {
      content: '';
      position: absolute;
      top: -18px;
      left: 75px;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-color: #000; }
      @media only screen and (max-width: 1024px) {
        .b-advantages__list-item:before {
          top: -18px;
          left: 40px;
          width: 60px;
          height: 60px; } }
      @media only screen and (max-width: 768px) {
        .b-advantages__list-item:before {
          left: 0; } }
      @media only screen and (max-width: 640px) {
        .b-advantages__list-item:before {
          top: 0;
          left: 50%;
          transform: translateX(-50%); } }
    .b-advantages__list-item::after {
      position: absolute;
      top: 2px;
      color: #fff;
      font-size: 30px; }
      @media only screen and (max-width: 1024px) {
        .b-advantages__list-item::after {
          top: -1px;
          font-size: 26px; } }
      @media only screen and (max-width: 640px) {
        .b-advantages__list-item::after {
          top: 17px;
          left: 50% !important;
          transform: translateX(-50%); } }
    .b-advantages__list-item_location::after {
      left: 98px; }
      @media only screen and (max-width: 1024px) {
        .b-advantages__list-item_location::after {
          left: 60px; } }
      @media only screen and (max-width: 768px) {
        .b-advantages__list-item_location::after {
          left: 20px; } }
    .b-advantages__list-item_favorite::after {
      left: 94px; }
      @media only screen and (max-width: 1024px) {
        .b-advantages__list-item_favorite::after {
          left: 56px; } }
      @media only screen and (max-width: 768px) {
        .b-advantages__list-item_favorite::after {
          left: 16px; } }
    .b-advantages__list-item_price::after {
      left: 95px; }
      @media only screen and (max-width: 1024px) {
        .b-advantages__list-item_price::after {
          left: 57px; } }
      @media only screen and (max-width: 768px) {
        .b-advantages__list-item_price::after {
          left: 17px; } }
    .b-advantages__list-item:last-child {
      margin-bottom: 0; }
  .b-advantages__title {
    margin: -7px 0 18px;
    color: #000;
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    letter-spacing: .06em;
    text-transform: uppercase; }
    .b-advantages__title_last {
      margin-bottom: -7px; }
  .b-advantages__text {
    margin: -8px 0;
    color: #000;
    font-size: 15px;
    line-height: 27px;
    letter-spacing: .02em; }

.b-breadcrumbs {
  display: flex;
  max-width: 100%;
  margin: -55px 0 41px; }
  @media only screen and (max-width: 1024px) {
    .b-breadcrumbs {
      margin: -45px 0 43px; } }
  @media only screen and (max-width: 640px) {
    .b-breadcrumbs {
      margin: -35px 0 39px; } }
  .b-breadcrumbs__inner {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: -10px 0; }
    @media only screen and (max-width: 1024px) {
      .b-breadcrumbs__inner {
        margin: -6px 0; } }
  .b-breadcrumbs__item {
    max-width: 100%;
    padding: 10px 0; }
    @media only screen and (max-width: 1024px) {
      .b-breadcrumbs__item {
        padding: 6px 0; } }
    .b-breadcrumbs__item:not(.b-breadcrumbs__item_active) {
      position: relative;
      padding-right: 58px; }
    .b-breadcrumbs__item:not(.b-breadcrumbs__item_active)::after {
      position: absolute;
      top: 12px;
      right: 20px;
      color: #47DBC1;
      font-size: 10px; }
      @media only screen and (max-width: 1024px) {
        .b-breadcrumbs__item:not(.b-breadcrumbs__item_active)::after {
          top: 8px; } }
      @media only screen and (max-width: 640px) {
        .b-breadcrumbs__item:not(.b-breadcrumbs__item_active)::after {
          top: 7px; } }
  .b-breadcrumbs__text {
    display: block;
    margin: -7px 0 -7px;
    color: #000;
    font-size: 20px;
    line-height: 28px;
    font-style: italic;
    letter-spacing: 0; }
    @media only screen and (max-width: 1024px) {
      .b-breadcrumbs__text {
        margin: -5px 0 -7px;
        font-size: 16px;
        line-height: 24px; } }
    @media only screen and (max-width: 640px) {
      .b-breadcrumbs__text {
        margin: -5px 0 -6px;
        font-size: 15px;
        line-height: 22px; } }
    a.b-breadcrumbs__text {
      color: #47DBC1;
      transition: color .2s; }
      a.b-breadcrumbs__text:hover {
        color: #23b399; }

.b-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 50px; }
  @media only screen and (max-width: 640px) {
    .b-content {
      margin-bottom: 45px; } }
  .b-content_w_sm {
    max-width: 825px; }
  .b-content_m_b_sm {
    margin-bottom: 40px; }
    @media only screen and (max-width: 640px) {
      .b-content_m_b_sm {
        margin-bottom: 35px; } }
  .b-content_m_b_lg {
    margin-bottom: 60px; }
    @media only screen and (max-width: 1024px) {
      .b-content_m_b_lg {
        margin-bottom: 55px; } }
    @media only screen and (max-width: 640px) {
      .b-content_m_b_lg {
        margin-bottom: 50px; } }
  .b-content_last {
    margin-bottom: 0; }
  .b-content__item_center {
    align-self: center; }
  .b-content h2 {
    margin: 43px 0 43px;
    color: #000;
    font-size: 32px;
    line-height: 44px;
    font-weight: 700;
    letter-spacing: .02em; }
    @media only screen and (max-width: 640px) {
      .b-content h2 {
        margin: 43px 0 28px;
        font-size: 28px;
        line-height: 36px; } }
    .b-content h2:first-child {
      margin-top: -7px; }
      @media only screen and (max-width: 640px) {
        .b-content h2:first-child {
          margin-top: -7px; } }
    .b-content h2:last-child {
      margin-bottom: -7px; }
      @media only screen and (max-width: 640px) {
        .b-content h2:last-child {
          margin-bottom: -7px; } }
    .b-content h2 + img,
    .b-content h2 + video,
    .b-content h2 + iframe,
    .b-content h2 + .b-content__block,
    .b-content h2 + button {
      margin-top: 0px; }
      @media only screen and (max-width: 640px) {
        .b-content h2 + img,
        .b-content h2 + video,
        .b-content h2 + iframe,
        .b-content h2 + .b-content__block,
        .b-content h2 + button {
          margin-top: -10px; } }
  .b-content h3 {
    margin: 40px 0 19px;
    color: #000;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: .02em; }
    .b-content h3:first-child {
      margin-top: -5px; }
    .b-content h3:last-child {
      margin-bottom: -6px; }
    .b-content h3 + h2 {
      margin-top: 18px; }
      @media only screen and (max-width: 640px) {
        .b-content h3 + h2 {
          margin-top: 18px; } }
    .b-content h3 + p {
      margin-top: 2px; }
      @media only screen and (max-width: 640px) {
        .b-content h3 + p {
          margin-top: 2px; } }
    .b-content h3 + .b-content__subtext {
      margin-top: -8px; }
    .b-content h3 + img,
    .b-content h3 + video,
    .b-content h3 + iframe,
    .b-content h3 + .b-content__block,
    .b-content h3 + button {
      margin-top: 25px; }
      @media only screen and (max-width: 640px) {
        .b-content h3 + img,
        .b-content h3 + video,
        .b-content h3 + iframe,
        .b-content h3 + .b-content__block,
        .b-content h3 + button {
          margin-top: 15px; } }
    .b-content h3 + ul,
    .b-content h3 + ol {
      margin-top: 10px; }
  .b-content p {
    margin: -8px 0 27px;
    color: #000;
    font-size: 15px;
    line-height: 27px;
    letter-spacing: .02em; }
    @media only screen and (max-width: 640px) {
      .b-content p {
        margin: -8px 0 27px; } }
    .b-content p:last-child {
      margin-bottom: -8px; }
    .b-content p + h2 {
      margin-top: 8px; }
      @media only screen and (max-width: 640px) {
        .b-content p + h2 {
          margin-top: 8px; } }
    .b-content p + h3 {
      margin-top: 5px; }
      @media only screen and (max-width: 640px) {
        .b-content p + h3 {
          margin-top: 5px; } }
    .b-content p + img,
    .b-content p + video,
    .b-content p + iframe,
    .b-content p + .b-content__block,
    .b-content p + button {
      margin-top: 15px; }
      @media only screen and (max-width: 640px) {
        .b-content p + img,
        .b-content p + video,
        .b-content p + iframe,
        .b-content p + .b-content__block,
        .b-content p + button {
          margin-top: 5px; } }
    .b-content p + ul,
    .b-content p + ol {
      margin-top: 0px; }
      @media only screen and (max-width: 640px) {
        .b-content p + ul,
        .b-content p + ol {
          margin-top: 0px; } }
  .b-content a:not(.-ol-btn):not(.b-content__not_link) {
    display: inline;
    border-bottom: 1px solid transparent;
    color: #50F6D9;
    line-height: inherit;
    letter-spacing: inherit;
    cursor: pointer;
    transition: all .2s; }
    .b-content a:not(.-ol-btn):not(.b-content__not_link):hover {
      border-bottom-color: #1ff4ce;
      color: #1ff4ce; }
  .b-content b,
  .b-content strong {
    line-height: 0;
    color: #000;
    font-weight: 700; }
  .b-content i {
    font-style: italic; }
  .b-content ul,
  .b-content ol {
    margin-bottom: 35px; }
    .b-content ul:first-child,
    .b-content ol:first-child {
      margin-top: 0; }
    .b-content ul:last-child,
    .b-content ol:last-child {
      margin-bottom: 0; }
    .b-content ul + h2,
    .b-content ol + h2 {
      margin-top: 8px; }
      @media only screen and (max-width: 640px) {
        .b-content ul + h2,
        .b-content ol + h2 {
          margin-top: 8px; } }
    .b-content ul + h3,
    .b-content ol + h3 {
      margin-top: 5px; }
    .b-content ul + p,
    .b-content ol + p {
      margin-top: -8px; }
      @media only screen and (max-width: 640px) {
        .b-content ul + p,
        .b-content ol + p {
          margin-top: -8px; } }
    .b-content ul + img,
    .b-content ul + video,
    .b-content ul + iframe,
    .b-content ul + .b-content__block,
    .b-content ul + button,
    .b-content ol + img,
    .b-content ol + video,
    .b-content ol + iframe,
    .b-content ol + .b-content__block,
    .b-content ol + button {
      margin-top: 15px; }
      @media only screen and (max-width: 640px) {
        .b-content ul + img,
        .b-content ul + video,
        .b-content ul + iframe,
        .b-content ul + .b-content__block,
        .b-content ul + button,
        .b-content ol + img,
        .b-content ol + video,
        .b-content ol + iframe,
        .b-content ol + .b-content__block,
        .b-content ol + button {
          margin-top: 5px; } }
  .b-content ol {
    counter-reset: content-list; }
  .b-content li {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    padding-left: 40px; }
    @media only screen and (max-width: 640px) {
      .b-content li {
        padding-left: 28px; } }
    .b-content li:last-child {
      margin-bottom: 0; }
  .b-content ul li::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    width: 11px;
    height: 1px;
    border-radius: 50%;
    background-color: #000; }
    @media only screen and (max-width: 640px) {
      .b-content ul li::before {
        width: 9px; } }
  .b-content ol li::before {
    counter-increment: content-list;
    content: counter(content-list) ".";
    position: absolute;
    left: 0;
    margin: -8px 0 -8px;
    color: #000;
    font-size: 15px;
    line-height: 27px;
    letter-spacing: .02em; }
  .b-content img,
  .b-content video,
  .b-content iframe, .b-content__block,
  .b-content button {
    margin-top: 50px;
    margin-bottom: 50px; }
    @media only screen and (max-width: 1024px) {
      .b-content img,
      .b-content video,
      .b-content iframe, .b-content__block,
      .b-content button {
        margin-top: 45px;
        margin-bottom: 45px; } }
    @media only screen and (max-width: 640px) {
      .b-content img,
      .b-content video,
      .b-content iframe, .b-content__block,
      .b-content button {
        margin-top: 40px;
        margin-bottom: 40px; } }
    .b-content img:first-child,
    .b-content video:first-child,
    .b-content iframe:first-child, .b-content__block:first-child,
    .b-content button:first-child {
      margin-top: 0; }
    .b-content img:last-child,
    .b-content video:last-child,
    .b-content iframe:last-child, .b-content__block:last-child,
    .b-content button:last-child {
      margin-bottom: 0; }
    .b-content img + h2,
    .b-content video + h2,
    .b-content iframe + h2, .b-content__block + h2,
    .b-content button + h2 {
      margin-top: -7px; }
      @media only screen and (max-width: 640px) {
        .b-content img + h2,
        .b-content video + h2,
        .b-content iframe + h2, .b-content__block + h2,
        .b-content button + h2 {
          margin-top: 3px; } }
  .b-content video,
  .b-content iframe, .b-content__block {
    display: block;
    max-width: 100%; }
  .b-content table {
    width: 100%;
    text-align: left; }
  .b-content tr {
    border-bottom-width: 1px color #ebebeb; }
  .b-content td,
  .b-content th {
    padding: 10px 10px;
    vertical-align: middle; }
    .b-content td:first-child,
    .b-content th:first-child {
      padding-left: 0; }
    .b-content td:last-child,
    .b-content th:last-child {
      padding-right: 0; }
  .b-content thead th {
    font-size: 1.1em;
    font-weight: 600; }
  .b-content dl {
    display: flex;
    margin-bottom: 30px; }
    .b-content dl:last-child {
      margin-bottom: 0; }
  .b-content dt {
    flex-shrink: 0;
    width: 200px;
    padding-right: 10px;
    font-weight: 600; }
    @media only screen and (max-width: 1024px) {
      .b-content dt {
        width: 140px;
        padding-right: 7px; } }
  .b-content__link-special {
    margin-top: -25px; }
    .b-content__link-special a {
      color: #69c1df;
      font-weight: 600;
      display: inline;
      border-bottom: 1px solid rgba(105, 193, 223, 0);
      color: #69c1df;
      line-height: inherit;
      letter-spacing: inherit;
      cursor: pointer;
      transition: all .2s; }
      .b-content__link-special a:hover {
        border-bottom-color: #69c1df;
        color: #69c1df; }

.b-footer {
  position: relative;
  overflow: hidden;
  padding: 30px 0;
  background-color: #000;
  color: #fff; }
  .b-footer::after {
    position: absolute;
    color: rgba(255, 255, 255, 0.07);
    font-size: 260px;
    display: block;
    position: absolute !important;
    top: 50%;
    left: 50%;
    white-space: nowrap;
    transform: translate(-50%, -50%); }
    @media only screen and (max-width: 640px) {
      .b-footer::after {
        display: none; } }
  .b-footer__inner {
    position: relative;
    z-index: 1; }
  .b-footer__top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: -15px -15px 40px; }
    @media only screen and (max-width: 640px) {
      .b-footer__top {
        margin-bottom: -15px 15px; } }
    .b-footer__top_last {
      margin-bottom: -15px; }
  .b-footer__top-item {
    margin: 15px; }
  .b-footer__social {
    display: flex;
    align-items: center; }
  .b-footer__social-title {
    position: relative;
    margin: -6px 50px -6px 0;
    color: #fff;
    font-size: 13px;
    line-height: 21px;
    font-weight: 700;
    letter-spacing: .06em;
    text-transform: uppercase; }
    .b-footer__social-title::before {
      content: '';
      position: absolute;
      left: calc( 100% + 15px);
      top: 8px;
      width: 20px;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.4); }
  .b-footer__social-list {
    display: flex;
    align-items: center; }
  .b-footer__social-list-item {
    margin-right: 30px; }
    .b-footer__social-list-item:last-child {
      margin-right: 0; }
    .b-footer__social-list-item::after {
      display: block;
      color: #50F6D9;
      transition: color .2s; }
    .b-footer__social-list-item:hover .b-footer__social-list-item::after {
      color: #fff; }
    .b-footer__social-list-item_fb::after {
      font-size: 22px; }
    .b-footer__social-list-item_int::after {
      font-size: 24px; }
  .b-footer__text {
    margin: -6px 0;
    color: #fff;
    font-size: 13px;
    line-height: 21px;
    font-weight: 600;
    letter-spacing: .04em; }
    .b-footer__text_highlight {
      color: #50F6D9; }
  .b-footer__bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -15px; }
  .b-footer__bottom-item {
    margin: 15px; }
    .b-footer__bottom-item_copyright {
      order: 1; }

.b-gallery {
  display: flex;
  flex-direction: column;
  padding: 0 20px; }
  .b-gallery__inner {
    display: flex;
    flex-wrap: wrap;
    margin: -5px; }
    @media only screen and (max-width: 640px) {
      .b-gallery__inner {
        margin: -10px; } }
  .b-gallery__item {
    width: 33.3333%;
    padding: 5px; }
    @media only screen and (max-width: 1024px) {
      .b-gallery__item {
        width: 50%; } }
    @media only screen and (max-width: 640px) {
      .b-gallery__item {
        width: 100%;
        padding: 10px; } }
  .b-gallery__box {
    position: relative;
    cursor: pointer; }
    .b-gallery__box::before {
      content: '';
      position: absolute;
      top: 15px;
      left: 15px;
      z-index: 1;
      width: calc( 100% - 30px);
      height: calc( 100% - 30px);
      background-color: rgba(0, 0, 0, 0.5);
      transform: scale(0);
      transition: transform .2s; }
  .b-gallery__box:hover::before {
    transform: scale(1); }
  .b-gallery__box::after {
    z-index: 1;
    opacity: 0;
    color: #fff;
    font-size: 30px;
    transition: opacity .2s;
    display: block;
    position: absolute !important;
    top: calc( 50% + 2px);
    left: 50%;
    white-space: nowrap;
    transform: translate(-50%, -50%); }
    @media only screen and (max-width: 1024px) {
      .b-gallery__box::after {
        font-size: 26px; } }
  .b-gallery__box:hover::after {
    opacity: 1; }
  .b-gallery__box-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .b-gallery__box-img-plug {
    padding-top: 56.5217391%; }

.b-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 53px;
  padding: 10px 30px;
  background-color: #fff; }
  @media only screen and (max-width: 1024px) {
    .b-header {
      padding: 10px 20px; } }
  .b-header__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1740px;
    margin: 0 auto; }
  .b-header__logo {
    position: relative;
    top: -1px;
    display: block; }
    .b-header__logo::after {
      display: block;
      color: #000;
      font-size: 34px; }
  .b-header__nav-hamburger {
    display: none; }
    @media only screen and (max-width: 1024px) {
      .b-header__nav-hamburger {
        display: block; } }
  @media only screen and (max-width: 1024px) {
    .b-header__nav-hamburger-btn {
      top: 23px;
      right: 23px; } }
  .b-header__nav-hamburger-btn::after {
    display: block;
    color: #000;
    font-size: 19px; }
  .b-header__nav-popup {
    position: fixed;
    top: 0;
    right: -280px;
    z-index: 99;
    overflow: auto;
    width: 280px;
    height: 100vh;
    padding: 60px 30px 30px;
    background: #000;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
    transition: right .2s;
    pointer-events: none; }
  .b-header__nav-hamburger_active .b-header__nav-popup {
    right: 0;
    pointer-events: auto; }
  .b-header__nav-popup-cross {
    position: absolute;
    top: 20px;
    right: 23px; }
    .b-header__nav-popup-cross::after {
      display: block;
      color: #fff;
      font-size: 15px;
      transform: rotate(0deg);
      transition: transform .2s; }
    .b-header__nav-popup-cross:hover::after {
      transform: rotate(90deg); }
  .b-header__nav-popup-list {
    position: relative;
    z-index: 1;
    margin-bottom: 40px; }
    .b-header__nav-popup-list_last {
      margin-bottom: 0; }
  .b-header__nav-popup-list-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding-bottom: 20px; }
    .b-header__nav-popup-list-item:last-child {
      margin-bottom: 0; }
  .b-header__nav-popup-link {
    display: block;
    margin: -5px 0 -7px;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: .04em;
    transition: color .2s; }
    .b-header__nav-popup-link:hover, .b-header__nav-popup-link_active {
      color: #50F6D9; }

.b-heading {
  display: flex;
  flex-direction: column;
  margin-bottom: 70px; }
  @media only screen and (max-width: 1024px) {
    .b-heading {
      margin-bottom: 55px; } }
  @media only screen and (max-width: 640px) {
    .b-heading {
      margin-bottom: 40px; } }
  .b-heading_center {
    text-align: center; }
  .b-heading_m_b_xxs {
    margin-bottom: 35px; }
    @media only screen and (max-width: 640px) {
      .b-heading_m_b_xxs {
        margin-bottom: 30px; } }
  .b-heading_m_b_xs {
    margin-bottom: 40px; }
    @media only screen and (max-width: 640px) {
      .b-heading_m_b_xs {
        margin-bottom: 35px; } }
  .b-heading_m_b_sm {
    margin-bottom: 50px; }
    @media only screen and (max-width: 1024px) {
      .b-heading_m_b_sm {
        margin-bottom: 45px; } }
    @media only screen and (max-width: 640px) {
      .b-heading_m_b_sm {
        margin-bottom: 40px; } }
  @media only screen and (max-width: 1024px) {
    .b-heading_m_b_xxs_tb {
      margin-bottom: 35px; } }
  @media only screen and (max-width: 640px) {
    .b-heading_m_b_xxs_tb {
      margin-bottom: 30px; } }
  .b-heading_m_b_lg {
    margin-bottom: 85px; }
    @media only screen and (max-width: 1024px) {
      .b-heading_m_b_lg {
        margin-bottom: 60px; } }
    @media only screen and (max-width: 640px) {
      .b-heading_m_b_lg {
        margin-bottom: 40px; } }
  .b-heading_m_b_xl {
    margin-bottom: 100px; }
    @media only screen and (max-width: 1024px) {
      .b-heading_m_b_xl {
        margin-bottom: 70px; } }
    @media only screen and (max-width: 640px) {
      .b-heading_m_b_xl {
        margin-bottom: 40px; } }
  .b-heading_last {
    margin-bottom: 0; }
  .b-heading__title {
    display: flex;
    flex-direction: column;
    margin-bottom: 17px; }
    .b-heading__title_m_b_lg {
      margin-bottom: 25px; }
      @media only screen and (max-width: 640px) {
        .b-heading__title_m_b_lg {
          margin-bottom: 20px; } }
    .b-heading__title:last-child {
      margin-bottom: 0; }
  .b-heading__title-inner {
    display: flex;
    flex-wrap: wrap;
    margin: -6px 0; }
  .b-heading_center .b-heading__title-inner {
    justify-content: center;
    text-align: center; }
  .b-heading__title-text {
    position: relative;
    margin: 6px 0; }
    .b-heading__title-text::before {
      content: '';
      position: absolute;
      top: 11px;
      left: 0;
      width: 100%;
      height: 6px;
      background: #50F6D9; }
      @media only screen and (max-width: 1024px) {
        .b-heading__title-text::before {
          top: 10px; } }
      @media only screen and (max-width: 640px) {
        .b-heading__title-text::before {
          top: 9px;
          height: 5px; } }
  .b-heading__title-text-inner {
    position: relative;
    z-index: 1;
    display: block;
    margin: -6px 0;
    color: #000;
    font-size: 40px;
    line-height: 1;
    font-weight: 700;
    letter-spacing: .02em;
    text-transform: uppercase; }
    @media only screen and (max-width: 1024px) {
      .b-heading__title-text-inner {
        margin: -5px 0;
        font-size: 36px; } }
    @media only screen and (max-width: 640px) {
      .b-heading__title-text-inner {
        margin: -6px 0 -8px;
        font-size: 30px;
        line-height: 36px; } }
  .b-heading__subtitle {
    margin: -7px 0 -8px;
    color: #000;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    letter-spacing: .02em; }
    @media only screen and (max-width: 1024px) {
      .b-heading__subtitle {
        margin: -6px 0 -8px;
        font-size: 22px;
        line-height: 30px; } }
    @media only screen and (max-width: 640px) {
      .b-heading__subtitle {
        margin: -7px 0;
        font-size: 20px;
        line-height: 28px; } }

.b-hero {
  margin-bottom: 100px;
  padding: 20px 20px 0; }
  @media only screen and (max-width: 1024px) {
    .b-hero {
      margin-bottom: 80px; } }
  @media only screen and (max-width: 640px) {
    .b-hero {
      margin-bottom: 70px; } }
  .b-hero_last {
    margin-bottom: 0; }
  .b-hero__inner {
    display: flex;
    flex-wrap: wrap;
    margin: -10px; }
  .b-hero__item {
    position: relative;
    display: block;
    width: calc( 33.3333333% - 20px);
    margin: 10px;
    text-align: center; }
    @media only screen and (max-width: 1024px) {
      .b-hero__item {
        width: calc( 50% - 20px); } }
    @media only screen and (max-width: 640px) {
      .b-hero__item {
        width: 100%; } }
    @media only screen and (max-width: 1024px) {
      .b-hero__item:first-child {
        width: 100%; } }
    .b-hero__item::before {
      content: '';
      position: absolute;
      top: 20px;
      left: 20px;
      width: calc( 100% - 40px);
      height: calc( 100% - 20px);
      box-shadow: 0px 25px 55px rgba(11, 23, 52, 0.15); }
  .b-hero__item-inner {
    position: relative;
    z-index: 1;
    height: 100%;
    background: #fff; }
    .b-hero__item-inner::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      border: 27px solid transparent;
      border-top-color: #50F6D9;
      border-right-color: #50F6D9; }
    @media only screen and (max-width: 1024px) {
      .b-hero__item-inner {
        border-width: 24px; } }
  .b-hero__visual {
    position: relative;
    overflow: hidden; }
  .b-hero__visual-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1);
    transition: transform .3s ease-in-out; }
  .b-hero__visual:hover .b-hero__visual-img {
    transform: scale(1.1); }
  .b-hero__visual-img-plug {
    padding-top: 57.3951435%; }
  @media only screen and (max-width: 1024px) {
    .b-hero__item:first-child .b-hero__visual-img-plug {
      padding-top: 35%; } }
  @media only screen and (max-width: 640px) {
    .b-hero__item:first-child .b-hero__visual-img-plug {
      padding-top: 57.3951435%; } }
  .b-hero__content {
    display: flex;
    flex-direction: column;
    padding: 35px 30px 30px; }
    @media only screen and (max-width: 1024px) {
      .b-hero__content {
        padding: 30px 30px 25px; } }
    @media only screen and (max-width: 768px) {
      .b-hero__content {
        padding: 30px 20px 25px; } }
    @media only screen and (max-width: 640px) {
      .b-hero__content {
        padding: 25px 20px; } }
  .b-hero__content-inner {
    width: 355px;
    max-width: 100%;
    margin: 0 auto; }
    @media only screen and (max-width: 640px) {
      .b-hero__content-inner {
        width: 100%; } }
  @media only screen and (max-width: 1024px) {
    .b-hero__item:first-child .b-hero__content-inner {
      width: 100%; } }
  .b-hero__title {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 20px; }
    @media only screen and (max-width: 1024px) {
      .b-hero__title {
        margin-bottom: 17px;
        padding-bottom: 17px; } }
  .b-hero__title-text {
    margin: -7px 0 -8px;
    color: #000;
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
    letter-spacing: .02em; }
    @media only screen and (max-width: 1024px) {
      .b-hero__title-text {
        margin: -7px 0;
        font-size: 20px;
        line-height: 28px; } }
    @media only screen and (max-width: 768px) {
      .b-hero__title-text {
        margin: -6px 0 -7px;
        font-size: 18px;
        line-height: 26px; } }
  .b-hero__text {
    margin: -6px 0 -7px;
    color: #000;
    font-size: 18px;
    line-height: 26px;
    font-style: italic;
    letter-spacing: .04em; }
    @media only screen and (max-width: 1024px) {
      .b-hero__text {
        margin: -5px 0 -6px;
        font-size: 15px;
        line-height: 22px; } }
    @media only screen and (max-width: 768px) {
      .b-hero__text {
        margin: -4px 0 -5px;
        font-size: 14px;
        line-height: 20px; } }

.b-nav {
  display: flex;
  flex-direction: column; }
  .b-nav__inner {
    display: flex;
    flex-wrap: wrap;
    margin: -10px -20px; }
  .b-nav__item {
    margin: 10px 20px; }
  .b-nav__link {
    position: relative;
    display: block; }
    .b-nav__link::before {
      content: '';
      position: absolute;
      top: 11px;
      left: 0;
      width: 0;
      height: 2px;
      background: #50F6D9; }
  .b-nav__link_active {
    pointer-events: none; }
    .b-nav__link_active::before {
      width: 100%; }
  .b-nav_light .b-nav__link_active::before {
    opacity: 0; }
  .b-nav__link-text {
    position: relative;
    z-index: 1;
    display: block;
    margin: -6px 0;
    color: #000;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    letter-spacing: .04em;
    transition: color .2s; }
  .b-nav__link:hover .b-nav__link-text {
    color: #47DBC1; }
  .b-nav_light .b-nav__link-text {
    color: #fff; }
  .b-nav_light .b-nav__link:hover .b-nav__link-text {
    color: #50F6D9; }

.b-news {
  margin-bottom: 100px; }
  @media only screen and (max-width: 1024px) {
    .b-news {
      margin-bottom: 80px; } }
  @media only screen and (max-width: 640px) {
    .b-news {
      margin-bottom: 70px; } }
  .b-news_last {
    margin-bottom: 0; }
  .b-news__list {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    padding: 0 20px; }
    @media only screen and (max-width: 1024px) {
      .b-news__list {
        margin-bottom: 15px; } }
    .b-news__list_last {
      margin-bottom: 0; }
  .b-news__list-inner {
    display: flex;
    flex-wrap: wrap;
    margin: -10px; }
    @media only screen and (max-width: 640px) {
      .b-news__list-inner {
        margin: 0; } }
  .b-news__list-item {
    width: calc( 50% - 20px);
    margin: 10px; }
    @media only screen and (max-width: 768px) {
      .b-news__list-item {
        width: 100%; } }
    @media only screen and (max-width: 640px) {
      .b-news__list-item {
        margin: 0; } }
  .b-news__box {
    position: relative;
    display: block; }
    .b-news__box::before {
      content: '';
      position: absolute;
      top: -20px;
      left: -20px;
      opacity: 0;
      width: calc( 100% + 40px);
      height: calc( 100% + 20px);
      background: #fff;
      box-shadow: 0px 15px 40px rgba(11, 23, 52, 0.12);
      transition: opacity .2s; }
      @media only screen and (max-width: 640px) {
        .b-news__box::before {
          display: none; } }
    .b-news__box:hover::before {
      opacity: 1; }
  .b-news__box-inner {
    position: relative;
    z-index: 1; }
  .b-news__box-date {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    min-width: 200px;
    padding: 28px 30px;
    background-color: #fff; }
    @media only screen and (max-width: 1250px) {
      .b-news__box-date {
        min-width: 165px;
        padding: 22px 25px; } }
    @media only screen and (max-width: 640px) {
      .b-news__box-date {
        min-width: 160px;
        padding: 17px 25px 18px; } }
    .b-news__box-date::before {
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      border: 35px solid transparent;
      border-top-color: #50F6D9;
      border-right-color: #50F6D9; }
      @media only screen and (max-width: 1250px) {
        .b-news__box-date::before {
          border-width: 28px; } }
      @media only screen and (max-width: 640px) {
        .b-news__box-date::before {
          border-width: 23px; } }
  .b-news__box-date-text {
    display: block;
    margin: -7px 0;
    color: #000;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    text-align: center; }
    @media only screen and (max-width: 1250px) {
      .b-news__box-date-text {
        margin: -5px 0 -7px;
        font-size: 16px;
        line-height: 24px; } }
    @media only screen and (max-width: 640px) {
      .b-news__box-date-text {
        margin: -5px 0 -6px;
        font-size: 15px;
        line-height: 22px; } }
  .b-news__visual {
    position: relative; }
  .b-news__visual-details {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc( 100% - 30px);
    height: calc( 100% - 30px); }
    .b-news__visual-details::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      transform: scale(0);
      transition: transform .2s; }
  .b-news__box:hover .b-news__visual-details::before {
    transform: scale(1); }
  .b-news__visual-details-text {
    position: relative;
    z-index: 1;
    opacity: 0;
    margin: -8px 0 -10px;
    color: #fff;
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    letter-spacing: .1em;
    text-transform: uppercase;
    transition: opacity .2s; }
    @media only screen and (max-width: 1250px) {
      .b-news__visual-details-text {
        margin: -9px 0 -8px;
        font-size: 28px;
        line-height: 36px; } }
    @media only screen and (max-width: 1024px) {
      .b-news__visual-details-text {
        margin: -7px 0;
        font-size: 25px;
        line-height: 32px; } }
  .b-news__box:hover .b-news__visual-details-text {
    opacity: 1; }
  .b-news__visual-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .b-news__visual-img-plug {
    padding-top: 56.5217391%; }
  .b-news__box-content {
    padding: 25px 20px; }
    @media only screen and (max-width: 1024px) {
      .b-news__box-content {
        text-align: center; } }
    @media only screen and (max-width: 640px) {
      .b-news__box-content {
        padding: 25px 20px 30px; } }
  .b-news__title {
    margin: -6px 0 9px;
    color: #000;
    font-size: 22px;
    line-height: 28px;
    font-weight: 700; }
    @media only screen and (max-width: 1250px) {
      .b-news__title {
        margin: -6px 0 9px;
        font-size: 20px;
        line-height: 26px; } }
    @media only screen and (max-width: 1024px) {
      .b-news__title {
        margin: -5px 0 9px;
        font-size: 18px;
        line-height: 24px; } }
    .b-news__title_last {
      margin-bottom: -8px; }
      @media only screen and (max-width: 1250px) {
        .b-news__title_last {
          margin-bottom: -8px; } }
      @media only screen and (max-width: 1024px) {
        .b-news__title_last {
          margin-bottom: -6px; } }
  .b-news__text {
    margin: -4px 0 -6px;
    color: #000;
    font-size: 16px;
    line-height: 22px; }
    @media only screen and (max-width: 1024px) {
      .b-news__text {
        margin: -5px 0 -6px;
        font-size: 15px;
        line-height: 22px; } }

.b-progress {
  margin-bottom: 60px; }
  @media only screen and (max-width: 1024px) {
    .b-progress {
      margin-bottom: 50px; } }
  @media only screen and (max-width: 640px) {
    .b-progress {
      margin-bottom: 45px; } }
  .b-progress_last {
    margin-bottom: 0; }
  .b-progress__top, .b-progress__bottom {
    display: flex;
    justify-content: space-between; }
  .b-progress__title {
    margin: -7px 0 5px;
    color: #000;
    font-size: 15px;
    line-height: 25px;
    font-weight: 600;
    letter-spacing: .02em; }
    .b-progress__title_last {
      margin-bottom: -7px; }
  .b-progress__bar {
    position: relative;
    height: 6px;
    margin-bottom: 15px;
    border-radius: 3px;
    background: #f2f2f2; }
    .b-progress__bar_last {
      margin-bottom: 0; }
  .b-progress__bar-active {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 3px;
    background: #50F6D9; }
  .b-progress__subtitle {
    margin: -6px 0 -7px;
    color: #666666;
    font-size: 14px;
    line-height: 24px;
    font-style: italic;
    letter-spacing: .02em; }

.b-section {
  padding: 70px 0; }
  @media only screen and (max-width: 1024px) {
    .b-section {
      padding: 60px 0; } }
  @media only screen and (max-width: 640px) {
    .b-section {
      padding: 45px 0; } }

.b-side-by-side {
  display: flex; }
  @media only screen and (max-width: 1024px) {
    .b-side-by-side {
      flex-wrap: wrap; } }
  .b-side-by-side__item {
    width: 50%; }
    @media only screen and (max-width: 1024px) {
      .b-side-by-side__item {
        width: 100%; } }
    .b-side-by-side__item_content {
      padding: 35px 10px; }
      @media only screen and (max-width: 1250px) {
        .b-side-by-side__item_content {
          padding: 20px 0; } }
      @media only screen and (max-width: 1024px) {
        .b-side-by-side__item_content {
          padding: 40px 0 0; } }
      @media only screen and (max-width: 640px) {
        .b-side-by-side__item_content {
          padding-top: 35px; } }
  .b-side-by-side__content {
    max-width: 600px; }
    @media only screen and (max-width: 1024px) {
      .b-side-by-side__content {
        max-width: 100%; } }

.b-subscribe {
  margin-bottom: 70px; }
  .b-subscribe_last {
    margin-bottom: 0; }
  .b-subscribe__form {
    display: flex;
    flex-direction: column; }
    @media only screen and (max-width: 768px) {
      .b-subscribe__form {
        max-width: 350px;
        margin: 0 auto; } }
  .b-subscribe__form-inner {
    display: flex;
    margin: -15px; }
    @media only screen and (max-width: 768px) {
      .b-subscribe__form-inner {
        flex-wrap: wrap; } }
  .b-subscribe__form-item {
    max-width: 100%;
    margin: 15px; }
    .b-subscribe__form-item_input {
      width: 255px; }
      @media only screen and (max-width: 768px) {
        .b-subscribe__form-item_input {
          width: 100%; } }
    .b-subscribe__form-item_btn {
      width: 350px; }
      @media only screen and (max-width: 768px) {
        .b-subscribe__form-item_btn {
          width: 100%; } }
  @media only screen and (max-width: 768px) {
    .b-subscribe__form-input {
      text-align: center; } }

.b-tabs {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px; }
  .b-tabs_last {
    margin-bottom: 0; }
  .b-tabs__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -6px -10px; }
    @media only screen and (max-width: 640px) {
      .b-tabs__inner {
        margin: -6px; } }
  .b-tabs__item {
    margin: 6px 10px; }
    @media only screen and (max-width: 640px) {
      .b-tabs__item {
        margin: 6px; } }
  .b-tabs__btn {
    position: relative;
    padding: 13px 20px;
    background: #000; }
    .b-tabs__btn::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: #50F6D9;
      transition: width .2s; }
  .b-tabs__btn:hover::before, .b-tabs__btn_active::before {
    width: 100%; }
  .b-tabs__text {
    position: relative;
    z-index: 1;
    display: block;
    margin: -6px 0 -7px;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: .04em; }
  .b-tabs__btn:hover .b-tabs__text,
  .b-tabs__btn_active .b-tabs__text {
    color: #000; }
