.b-hero {
	$p: 20px;

	margin-bottom: 100px;
	padding: $p $p 0;

	@include md-tablet {
		margin-bottom: 80px; }

	@include md-mobile {
		margin-bottom: 70px; }

	&_last {
		margin-bottom: 0; }

	&__inner { // tag a
		display: flex;
		flex-wrap: wrap;
		margin: -$p / 2; }

	&__item { // tag a
		position: relative; // for &::before

		display: block;

		width: calc( 33.3333333% - #{ $p } );

		margin: $p / 2;

		text-align: center;

		@include md-tablet {
			width: calc( 50% - #{ $p } ); }

		@include md-mobile {
			width: 100%; }

		&:first-child {

			@include md-tablet {
				width: 100%; } }

		&::before {
			content: '';

			position: absolute;
			top: 20px;
			left: 20px;

			width: calc( 100% - 40px );
			height: calc( 100% - 20px );
			box-shadow: 0px 25px 55px rgba( $b_sh_c, .15 ); } }

	&__item-inner {
		position: relative;
		z-index: 1; // to be over &__item::before

		height: 100%;

		background: $white;

		&::before {
			content: '';

			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;

			border: 27px solid transparent;
			border-top-color: $brand-primary;
			border-right-color: $brand-primary; }

		@include md-tablet {
			border-width: 24px; } }

	&__visual {
		position: relative;
		overflow: hidden; }

	&__visual-img {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		object-fit: cover;

		transform: scale( 1 );
		transition: transform .3s ease-in-out; }

	&__visual:hover &__visual-img {
		transform: scale( 1.1 ); }

	&__visual-img-plug {
		padding-top: 57.3951435%; }

	&__item:first-child &__visual-img-plug {

		@include md-tablet {
			padding-top: 35%; }

		@include md-mobile {
			padding-top: 57.3951435%; } }

	&__content {
		display: flex; // to collapse margins inside
		flex-direction: column;
		padding: 35px 30px 30px;

		@include md-tablet {
			padding: 30px 30px 25px; }

		@include md-tablet_sm {
			padding: 30px 20px 25px; }

		@include md-mobile {
			padding: 25px 20px; } }

	&__content-inner {
		width: 355px;
		max-width: 100%;
		margin: 0 auto;

		@include md-mobile {
			width: 100%; } }

	&__item:first-child &__content-inner {

		@include md-tablet {
			width: 100%; } }

	&__title {
		display: flex; // to collapse margins inside
		flex-direction: column;

		margin-bottom: 20px;
		border-bottom: $brd_w solid $grey-light;
		padding-bottom: 20px;

		@include md-tablet {
			margin-bottom: 17px;
			padding-bottom: 17px; } }

	&__title-text {
		margin: -7px 0 -8px;

		color: $black;
		font-size: 22px;
		line-height: 30px;
		font-weight: 600;
		letter-spacing: .02em;

		@include md-tablet {
			margin: -7px 0;
			font-size: 20px;
			line-height: 28px; }

		@include md-tablet_sm {
			margin: -6px 0 -7px;
			font-size: 18px;
			line-height: 26px; } }

	&__text {
		margin: -6px 0 -7px;

		color: $black;
		font-size: 18px;
		line-height: 26px;
		font-style: italic;
		letter-spacing: .04em;

		@include md-tablet {
			margin: -5px 0 -6px;
			font-size: 15px;
			line-height: 22px; }

		@include md-tablet_sm {
			margin: -4px 0 -5px;
			font-size: 14px;
			line-height: 20px; } } }
