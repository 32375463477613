.l-content-wrapper {
	display: flex;
	flex-direction: column;

	min-height: 100vh;

	padding-top: $h-b-header;

	transition: $anim_dur-b-modal filter;

	._modal_active & {
		filter: blur( 3px ); }

	&__item {

		&_major {
			flex-grow: 1;

			width: 100%;
			max-width: $w_max-l-content-wrapper;

			margin: 0 auto; }

		&_shrink_none {
			flex-shrink: 0; } } }
