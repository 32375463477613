.-ol-container {

	&_sm {
		max-width: $w-container_sm;

		@include md-tablet {
			max-width: $w-container_sm - ( $m-col - $m-col_tb ) * 2;
			width: $w-container_tb; } }

	&_xs {
		max-width: $w-container_xs;

		@include md-tablet {
			max-width: $w-container_sm - ( $m-col - $m-col_tb ) * 2;
			width: $w-container_tb; } } }
