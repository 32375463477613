.b-content {
	$m_t-h2:				50px;
	$m_t-h2_mb:				50px;
	$m_t-h2_inner:			7px;
	$m_t-h2_inner_mb:		7px;
	$m_b-h2:				50px;
	$m_b-h2_mb:				35px;
	$m_b-h2_inner:			7px;
	$m_b-h2_inner_mb:		7px;

	$m_t-h3:				45px;
	$m_t-h3_inner:			5px;
	$m_b-h3:				25px;
	$m_b-h3_inner:			6px;

	$m_t-p:					35px;
	$m_t-p_mb:				35px;
	$m_t-p_inner:			8px;
	$m_b-p:					35px;
	$m_b-p_mb:				35px;
	$m_b-p_inner:			8px;

	$m_t-ul:				35px;
	$m_b-ul:				35px;
	$m_b-ul_lg:				35px;

	$m_t-img:				50px;
	$m_t-img_tb:			45px;
	$m_t-img_mb:			40px;
	$m_b-img:				50px;
	$m_b-img_tb:			45px;
	$m_b-img_mb:			40px;

	display: flex;
	flex-direction: column;
	align-items: start; // to don't stretch images and other tags if it less than 100%

	margin-bottom: 50px;

	@include md-mobile {
		margin-bottom: 45px; }

	&_w_sm {
		max-width: 825px; }

	&_m_b_sm {
		margin-bottom: 40px;

		@include md-mobile {
			margin-bottom: 35px; } }

	&_m_b_lg {
		margin-bottom: 60px;

		@include md-tablet {
			margin-bottom: 55px; }

		@include md-mobile {
			margin-bottom: 50px; } }

	&_last {
		margin-bottom: 0; }

	&__item {

		&_center {
			align-self: center; } }

	h2 {
		margin: ( $m_t-h2 - $m_t-h2_inner ) 0 ( $m_b-h2 - $m_b-h2_inner );

		color: $black;
		font-size: 32px;
		line-height: 44px;
		font-weight: 700;
		letter-spacing: .02em;

		@include md-mobile {
			margin: ( $m_t-h2_mb - $m_t-h2_inner_mb ) 0 ( $m_b-h2_mb - $m_b-h2_inner_mb );
			font-size: 28px;
			line-height: 36px; }

		&:first-child {
			margin-top: -$m_t-h2_inner;

			@include md-mobile {
				margin-top: -$m_t-h2_inner_mb; } }

		&:last-child {
			margin-bottom: -$m_b-h2_inner;

			@include md-mobile {
				margin-bottom: -$m_b-h2_inner_mb; } }

		+ h3 {

			@if $m_t-h3 >= $m_b-h2 {
				margin-top: ( $m_t-h3 - $m_t-h3_inner - $m_b-h2_inner ) - ( $m_b-h2 - $m_b-h2_inner );

				@include md-mobile {
					margin-top: ( $m_t-h3 - $m_t-h3_inner - $m_b-h2_inner_mb ) - ( $m_b-h2_mb - $m_b-h2_inner_mb ); } } }

		+ p {

			@if $m_t-p >= $m_b-h2 {
				margin-top: ( $m_t-p - $m_t-p_inner - $m_b-h2_inner ) - ( $m_b-h2 - $m_b-h2_inner );

				@include md-mobile {
					margin-top: ( $m_t-p_mb - $m_t-p_inner - $m_b-h2_inner_mb ) - ( $m_b-h2_mb - $m_b-h2_inner_mb ); } } }

		+ img,
		+ video,
		+ iframe,
		+ .b-content__block,
		+ button {

			@if $m_t-img >= $m_b-h2 {
				margin-top: ( $m_t-img - $m_b-h2_inner ) - ( $m_b-h2 - $m_b-h2_inner );

				@include md-mobile {
					margin-top: ( $m_t-img_mb - $m_b-h2_inner ) - ( $m_b-h2 - $m_b-h2_inner ); } } }

		+ ul,
		+ ol {

			@if $m_t-ul >= $m_b-h2 {
				margin-top: ( $m_t-ul - $m_b-h2_inner ) - ( $m_b-h2 - $m_b-h2_inner ); } } }

	h3 {
		margin: ( $m_t-h3 - $m_t-h3_inner ) 0 ( $m_b-h3 - $m_b-h3_inner );

		color: $black;
		font-size: 18px;
		line-height: 24px;
		font-weight: 600;
		letter-spacing: .02em;


		&:first-child {
			margin-top: -$m_t-h3_inner; }

		&:last-child {
			margin-bottom: -$m_b-h3_inner; }

		+ h2 {

			@if $m_t-h2 >= $m_b-h3 {
				margin-top: ( $m_t-h2 - $m_t-h2_inner - $m_b-h3_inner ) - ( $m_b-h3 - $m_b-h3_inner );

				@include md-mobile {
					margin-top: ( $m_t-h2_mb - $m_t-h2_inner_mb - $m_b-h3_inner ) - ( $m_b-h3 - $m_b-h3_inner ); } } }

		+ p {

			@if $m_t-p >= $m_b-h3 {
				margin-top: ( $m_t-p - $m_t-p_inner - $m_b-h3_inner ) - ( $m_b-h3 - $m_b-h3_inner );

				@include md-mobile {
					margin-top: ( $m_t-p_mb - $m_t-p_inner - $m_b-h3_inner ) - ( $m_b-h3 - $m_b-h3_inner ); } } }

		+ .b-content__subtext {
			margin-top: -$m_t-p_inner; }

		+ img,
		+ video,
		+ iframe,
		+ .b-content__block,
		+ button {

			@if $m_t-img >= $m_b-h3 {
				margin-top: ( $m_t-img - $m_b-h3_inner ) - ( $m_b-h3 - $m_b-h3_inner );

				@include md-mobile {
					margin-top: ( $m_t-img_mb - $m_b-h3_inner ) - ( $m_b-h3 - $m_b-h3_inner ); } } }

		+ ul,
		+ ol {

			@if $m_t-ul >= $m_b-h3 {
				margin-top: ( $m_t-ul - $m_b-h3_inner ) - ( $m_b-h3 - $m_b-h3_inner ); } } }

	p {
		margin: -$m_t-p_inner 0 ( $m_b-p - $m_b-p_inner );

		@include md-mobile {
			margin: -$m_t-p_inner 0 ( $m_b-p_mb - $m_b-p_inner ); }

		color: $black;
		font-size: 15px;
		line-height: 27px;
		letter-spacing: .02em;

		&:last-child {
			margin-bottom: -$m_b-p_inner; }

		+ h2 {

			@if $m_t-h2 >= $m_b-p {
				margin-top: ( $m_t-h2 - $m_t-h2_inner - $m_b-p_inner ) - ( $m_b-p - $m_b-p_inner );

				@include md-mobile {
					margin-top: ( $m_t-h2 - $m_t-h2_inner - $m_b-p_inner ) - ( $m_b-p_mb - $m_b-p_inner ); } } }

		+ h3 {

			@if $m_t-h3 >= $m_b-p {
				margin-top: ( $m_t-h3 - $m_t-h3_inner - $m_b-p_inner ) - ( $m_b-p - $m_b-p_inner );

				@include md-mobile {
					margin-top: ( $m_t-h3 - $m_t-h3_inner - $m_b-p_inner ) - ( $m_b-p_mb - $m_b-p_inner ); } } }

		+ img,
		+ video,
		+ iframe,
		+ .b-content__block,
		+ button {

			@if $m_t-img >= $m_b-p {
				margin-top: ( $m_t-img - $m_b-p_inner ) - ( $m_b-p - $m_b-p_inner );

				@include md-mobile {
					margin-top: ( $m_t-img_mb - $m_b-p_inner ) - ( $m_b-p_mb - $m_b-p_inner ); } } }

		+ ul,
		+ ol {

			@if $m_t-ul >= $m_b-p {
				margin-top: $m_t-ul - $m_b-p;

				@include md-mobile {
					margin-top: $m_t-ul - $m_b-p_mb; } } } }

	a:not( .-ol-btn ):not( .b-content__not_link ) {

		@include link-variant; }

	b,
	strong {
		line-height: 0; // to fix bold alignment
		color: $black;
		font-weight: 700; }

	i {
		font-style: italic; }

	ul,
	ol {
		margin-bottom: $m_b-ul;

		&:first-child {
			margin-top: 0; }

		&:last-child {
			margin-bottom: 0; }

		+ h2 {

			@if $m_t-h2 >= $m_b-ul {
				margin-top: ( $m_t-h2 - $m_t-h2_inner ) - $m_b-ul;

				@include md-mobile {
					margin-top: ( $m_t-h2_mb - $m_t-h2_inner_mb ) - $m_b-ul; } } }

		+ h3 {

			@if $m_t-h3 >= $m_b-ul {
				margin-top: ( $m_t-h3 - $m_t-h3_inner ) - $m_b-ul; } }

		+ p {

			@if $m_t-p >= $m_b-ul {
				margin-top: ( $m_t-p - $m_t-p_inner ) - $m_b-ul;

				@include md-mobile {
					margin-top: ( $m_t-p_mb - $m_t-p_inner ) - $m_b-ul; } } }

		+ img,
		+ video,
		+ iframe,
		+ .b-content__block,
		+ button {

			@if $m_t-img >= $m_b-ul {
				margin-top: ( $m_t-img - $m_b-p_inner ) - ( $m_b-p - $m_b-p_inner );

				@include md-mobile {
					margin-top: ( $m_t-img_mb - $m_b-p_inner ) - ( $m_b-p_mb - $m_b-p_inner ); } } } }

	ol {
		counter-reset: content-list; }

	li {
		position: relative;

		display: flex;
		flex-direction: column;

		margin-bottom: 25px;
		padding-left: 40px;

		@include md-mobile {
			padding-left: 28px; }

		&:last-child {
			margin-bottom: 0; } }

	ul li {

		&::before {
			content: '';

			position: absolute;
			top: 5px;
			left: 0;

			width: 11px;
			height: 1px;

			border-radius: 50%;

			background-color: $black;

			@include md-mobile {
				width: 9px; } } }

	ol li {

		&::before {
			counter-increment: content-list;
			content: counter( content-list ) '.';

			position: absolute;
			left: 0;

			margin: ( -$m_t-p_inner ) 0 ( -$m_b-p_inner );

			color: $black;
			font-size: 15px;
			line-height: 27px;
			letter-spacing: .02em; } }

	img,
	video,
	iframe,
	&__block,
	button {
		margin-top: $m_t-img;
		margin-bottom: $m_b-img;

		@include md-tablet {
			margin-top: $m_t-img_tb;
			margin-bottom: $m_b-img_tb; }

		@include md-mobile {
			margin-top: $m_t-img_mb;
			margin-bottom: $m_b-img_mb; }

		&:first-child {
			margin-top: 0; }

		&:last-child {
			margin-bottom: 0; }

		+ h2 {

			@if $m_t-h2 >= $m_b-img {
				margin-top: ( $m_t-h2 - $m_t-h2_inner ) - $m_b-img;

				@include md-mobile {
					margin-top: ( $m_t-h2_mb - $m_t-h2_inner_mb ) - $m_b-img_mb; } } }

		+ h3 {

			@if $m_t-h3 >= $m_b-img {
				margin-top: ( $m_t-h3 - $m_t-h3_inner ) - $m_b-img;

				@include md-mobile {
					margin-top: ( $m_t-h3 - $m_t-h3_inner ) - $m_b-img_mb; } } }

		+ p {

			@if $m_t-p >= $m_b-img {
				margin-top: ( $m_t-p - $m_t-p_inner ) - $m_b-img;

				@include md-mobile {
					margin-top: ( $m_t-p_mb - $m_t-p_inner ) - $m_b-img_mb; } } }

		+ ul,
		+ ol {

			@if $m_t-ul >= $m_b-img {
				margin-top: $m_t-ul - $m_b-img;

				@include md-mobile {
					margin-top: $m_t-ul - $m_b-img_mb; } } } }

	video,
	iframe,
	&__block {
		display: block;
		max-width: 100%; }

	table {
		width: 100%;
		text-align: left; }

	tr {
		border-bottom-width: $brd_w color $table_hr; }

	td,
	th {
		padding: $p_vert-td $p_horiz-td;
		vertical-align: middle;

		&:first-child {
			padding-left: 0; }

		&:last-child {
			padding-right: 0; } }

	thead {

		th {
			font-size: 1.1em;
			font-weight: 600; } }

	dl {
		display: flex;
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0; } }

	dt {
		flex-shrink: 0;

		width: 200px;

		padding-right: 10px;

		font-weight: 600;

		@include md-tablet {
			width: 140px;
			padding-right: 7px; } }

	&__link-special {
		margin-top: -25px;

		a {
			color: $brand-info;
			font-weight: 600;

			@include link-variant( $brand-info, rgba( $brand-info, 0 ), $brand-info, $brand-info ); } } }

