.b-gallery {
	display: flex; // to collapse margins inside
	flex-direction: column;
	padding: 0 20px;

	&__inner {
		display: flex;
		flex-wrap: wrap;
		margin: -5px;

		@include md-mobile {
			margin: -10px; } }

	&__item {
		width: 33.3333%;
		padding: 5px;

		@include md-tablet {
			width: 50%; }

		@include md-mobile {
			width: 100%;
			padding: 10px; } }

	&__box {
		position: relative; // for &::before and &::after
		cursor: pointer;

		@extend .i-magnifier;

		&::before {
			content: '';

			position: absolute;
			top: 15px;
			left: 15px;
			z-index: 1;

			width: calc( 100% - 30px );
			height: calc( 100% - 30px );

			background-color: rgba( $black, .5 );

			transform: scale( 0 );

			transition: transform .2s; } }

	&__box:hover::before {
		transform: scale( 1 ); }

	&__box::after {
		z-index: 1;
		opacity: 0;

		color: $white;
		font-size: 30px;

		transition: opacity .2s;

		@include center-tsf( $y: calc( 50% + 2px ) );

		@include md-tablet {
			font-size: 26px; } }

	&__box:hover::after {
		opacity: 1; }

	&__box-img {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		object-fit: cover; }

	&__box-img-plug {
		padding-top: 56.5217391%; } }
