.b-advantages {
	margin-bottom: 100px;

	@include md-tablet {
		margin-bottom: 80px; }

	@include md-mobile {
		margin-bottom: 70px;
		text-align: center; }

	&_last {
		margin-bottom: 0; }

	&__list {}

	&__list-item {
		position: relative;

		display: flex; // to collapse margins inside
		flex-direction: column;

		margin-bottom: 50px;
		padding-left: 190px;

		@include md-tablet {
			padding-left: 130px; }

		@include md-tablet_sm {
			margin-bottom: 45px;
			padding-left: 85px; }

		@include md-mobile {
			margin-bottom: 40px;
			padding-top: 80px;
			padding-left: 0; }

		&:before {
			content: '';

			position: absolute;
			top: -18px;
			left: 75px;

			width: 70px;
			height: 70px;

			border-radius: 50%;

			background-color: $black;

			@include md-tablet {
				top: -18px;
				left: 40px;

				width: 60px;
				height: 60px; }

			@include md-tablet_sm {
				left: 0; }

			@include md-mobile {
				top: 0;
				left: 50%;
				transform: translateX( -50% ); } }

		&::after {
			position: absolute;
			top: 2px;

			color: $white;
			font-size: 30px;

			@include md-tablet {
				top: -1px;
				font-size: 26px; }

			@include md-mobile {
				top: 17px;
				left: 50% !important;
				transform: translateX( -50% ); } }

		&_location {

			@extend .i-point;

			&::after {
				left: 98px;

				@include md-tablet {
					left: 60px; }

				@include md-tablet_sm {
					left: 20px; } } }

		&_favorite {

			@extend .i-star;

			&::after {
				left: 94px;

				@include md-tablet {
					left: 56px; }

				@include md-tablet_sm {
					left: 16px; } } }

		&_price {

			@extend .i-price;

			&::after {
				left: 95px;

				@include md-tablet {
					left: 57px; }

				@include md-tablet_sm {
					left: 17px; } } }

		&:last-child {
			margin-bottom: 0; } }

	&__title {
		margin: -7px 0 18px;

		color: $black;
		font-size: 20px;
		line-height: 28px;
		font-weight: 700;
		letter-spacing: .06em;

		text-transform: uppercase;

		&_last {
			margin-bottom: -7px; } }

	&__text {
		margin: -8px 0;

		color: $black;
		font-size: 15px;
		line-height: 27px;
		letter-spacing: .02em; } }
