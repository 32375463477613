.b-side-by-side {
	display: flex;

	@include md-tablet {
		flex-wrap: wrap; }

	&__item {
		width: 50%;

		@include md-tablet {
			width: 100%; }

		&_content {
			padding: 35px 10px;

			@include md-desk_sm {
				padding: 20px 0; }

			@include md-tablet {
				padding: 40px 0 0; }

			@include md-mobile {
				padding-top: 35px; } } }

	&__content {
		max-width: 600px;

		@include md-tablet {
			max-width: 100%; } } }
