.b-breadcrumbs {
	$p_vert-item: 10px;
	$p_vert-item_tb: 6px;

	display: flex;
	max-width: 100%; // to make +text-ellipsis work inside
	margin: -55px 0 41px;

	@include md-tablet {
		margin: -45px 0 43px; }

	@include md-mobile {
		margin: -35px 0 39px; }

	&__inner {
		display: flex;
		flex-wrap: wrap;

		width: 100%; // to make +text-ellipsis work inside

		margin: ( -$p_vert-item ) 0;

		@include md-tablet {
			margin: ( -$p_vert-item_tb ) 0; } }

	&__item {
		max-width: 100%; // to make +text-ellipsis work inside
		padding: $p_vert-item 0;

		@include md-tablet {
			padding: $p_vert-item_tb 0; }

		&:not( &_active ) {
			position: relative;
			padding-right: 58px;

			@extend .i-arrow; }

		&:not( &_active )::after {
			position: absolute;
			top: $p_vert-item + 2px;
			right: 20px;

			color: $brand-primary_dark;
			font-size: 10px;

			@include md-tablet {
				top: $p_vert-item_tb + 2px; }

			@include md-mobile {
				top: $p_vert-item_tb + 1px; } } }

	&__text {
		display: block; // tag a

		margin: -7px 0 -7px;

		color: $black;
		font-size: 20px;
		line-height: 28px;
		font-style: italic;
		letter-spacing: 0;

		@include md-tablet {
			margin: -5px 0 -7px;
			font-size: 16px;
			line-height: 24px; }

		@include md-mobile {
			margin: -5px 0 -6px;
			font-size: 15px;
			line-height: 22px; }

		@at-root {

			a#{&} {
				color: $brand-primary_dark;
				transition: color .2s;

				&:hover {
					color: darken( $brand-primary_dark, 15% ); } } } } }
