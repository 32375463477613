.i-menu,
.i-cross,
.i-arrow,
.i-logo,
.i-price,
.i-point,
.i-star,
.i-instagram,
.i-facebook,
.i-magnifier {

	@include font-sprite; }

.i-menu {

	&::after {
		content: '\e908'; } }

.i-cross {

	&::after {
		content: '\e909'; } }

.i-arrow {

	&::after {
		content: '\e907'; } }

.i-logo {

	&::after {
		content: '\e900'; } }

.i-price {

	&::after {
		content: '\e901'; } }

.i-point {

	&::after {
		content: '\e902'; } }

.i-star {

	&::after {
		content: '\e903'; } }

.i-instagram {

	&::after {
		content: '\e904'; } }

.i-facebook {

	&::after {
		content: '\e905'; } }

.i-magnifier {

	&::after {
		content: '\e906'; } }
