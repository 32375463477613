.b-footer {
	$m-item: 15px;

	position: relative; // for &::after

	overflow: hidden;

	padding: 30px 0;

	background-color: $black;

	color: $white;

	@extend .i-logo;

	&::after {
		position: absolute;
		color: rgba( $white, .07 );
		font-size: 260px;

		@include center-tsf;

		@include md-mobile {
			display: none; } }

	&__inner {
		position: relative;
		z-index: 1; } // to be over &&::after

	&__top {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;

		margin: ( -$m-item ) ( -$m-item ) ( 55px - $m-item );

		@include md-mobile {
			margin-bottom: -$m-item ( 30px - $m-item ); }

		&_last {
			margin-bottom: -$m-item; } }

	&__top-item {
		margin: $m-item; }

	&__social {
		display: flex;
		align-items: center; }

	&__social-title {
		position: relative; // for &::before

		margin: -6px 50px -6px 0;

		color: $white;
		font-size: 13px;
		line-height: 21px;
		font-weight: 700;
		letter-spacing: .06em;

		text-transform: uppercase;

		&::before {
			content: '';

			position: absolute;
			left: calc( 100% + 15px );
			top: 8px;

			width: 20px;
			height: 1px;

			background-color: rgba( $white, .4 ); } }

	&__social-list {
		display: flex;
		align-items: center; }

	&__social-list-item {
		margin-right: 30px;

		&:last-child {
			margin-right: 0; }

		&::after {
			display: block;
			color: $brand-primary;
			transition: color .2s; }

		&:hover &::after {
			color: $white; }

		&_fb {

			@extend .i-facebook;

			&::after {
				font-size: 22px; } }

		&_int {

			@extend .i-instagram;

			&::after {
				font-size: 24px; } } }

	&__text {
		margin: -6px 0;

		color: $white;
		font-size: 13px;
		line-height: 21px;
		font-weight: 600;
		letter-spacing: .04em;

		&_highlight {
			color: $brand-primary; } }

	&__bottom {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		margin: -$m-item; }

	&__bottom-item {
		margin: $m-item;

		&_copyright {
			order: 1; } } }
