.b-header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: $z_i-b-header;

	width: 100%;
	height: $h-b-header;

	padding: 10px $m-col;

	background-color: $white;

	@include md-tablet {
		padding: 10px $m-col_tb; }

	&__inner {
		display: flex;
		align-items: center;
		justify-content: space-between;

		max-width: $w_max-l-content-wrapper;

		margin: 0 auto; }

	&__item {}

	&__logo { // tag a
		position: relative;
		top: -1px;
		display: block;

		@extend .i-logo;

		&::after {
			display: block;
			color: $black;
			font-size: 34px; } }

	&__nav-hamburger {
		display: none;

		@include md-tablet {
			display: block; } }

	&__nav-hamburger-btn {

		@extend .i-menu;

		@include md-tablet {
			top: 23px;
			right: 23px; }

		&::after {
			display: block;
			color: $black;
			font-size: 19px; } }

	&__nav-popup {
		position: fixed;
		top: 0;
		right: -280px;
		z-index: $z_i-b-header-nav-mb;

		overflow: auto;

		width: 280px;
		height: 100vh;

		padding: 60px 30px 30px;

		background: $black;
		box-shadow: 0px 15px 30px rgba( $black, .1 );

		transition: right .2s;

		pointer-events: none; }

	&__nav-hamburger_active &__nav-popup {
		right: 0;
		pointer-events: auto; }

	&__nav-popup-cross {
		position: absolute;
		top: 20px;
		right: 23px;

		@extend .i-cross;

		&::after {
			display: block;

			color: $white;
			font-size: 15px;

			transform: rotate( 0deg );

			transition: transform .2s; }

		&:hover::after {
			transform: rotate( 90deg ); } }

	&__nav-popup-list {
		position: relative;
		z-index: 1;
		margin-bottom: 40px;

		&_last {
			margin-bottom: 0; } }

	&__nav-popup-list-item {
		display: flex;
		flex-direction: column;

		margin-bottom: 20px;
		border-bottom: 1px solid rgba( $white, .15 );
		padding-bottom: 20px;

		&:last-child {
			margin-bottom: 0; } }

	&__nav-popup-link { // tag a
		display: block;

		margin: -5px 0 -7px;

		color: $white;
		font-size: 16px;
		line-height: 24px;
		font-weight: 500;
		letter-spacing: .04em;

		transition: color .2s;

		&:hover,
		&_active {
			color: $brand-primary; } } }
