.b-progress {
	margin-bottom: 60px;

	@include md-tablet {
		margin-bottom: 50px; }

	@include md-mobile {
		margin-bottom: 45px; }

	&_last {
		margin-bottom: 0; }

	&__top,
	&__bottom {
		display: flex;
		justify-content: space-between; }

	&__top {}

	&__title {
		margin: -7px 0 5px;

		color: $black;
		font-size: 15px;
		line-height: 25px;
		font-weight: 600;
		letter-spacing: .02em;

		&_last {
			margin-bottom: -7px; } }

	&__bar {
		$h: 6px;
		position: relative; // &__bar

		height: $h;

		margin-bottom: 15px;
		border-radius: $h/2;

		background: $grey-lighter;

		&_last {
			margin-bottom: 0; } }

	&__bar-active {
		position: absolute;
		top: 0;
		left: 0;

		height: 100%;

		border-radius: 3px;

		background: $brand-primary; }

	&__bottom {}

	&__subtitle {
		margin: -6px 0 -7px;

		color: $grey;
		font-size: 14px;
		line-height: 24px;
		font-style: italic;
		letter-spacing: .02em; } }
