.b-nav {
	display: flex; // to collapse margins inside
	flex-direction: column;

	&__inner {
		display: flex;
		flex-wrap: wrap;
		margin: -10px -20px; }

	&__item {
		margin: 10px 20px; }

	&__link { // tag a
		position: relative;
		display: block;

		&::before {
			content: '';

			position: absolute;
			top: 11px;
			left: 0;

			width: 0;
			height: 2px;

			background: $brand-primary; } }

	&__link_active {
		pointer-events: none;

		&::before {
			width: 100%; } }

	&_light &__link_active {

		&::before {
			opacity: 0; } }

	&__link-text { // tag span
		position: relative;
		z-index: 1; // to be over &__link::before

		display: block;

		margin: -6px 0;

		color: $black;
		font-size: 14px;
		line-height: 22px;
		font-weight: 600;
		letter-spacing: .04em;

		transition: color .2s; }

	&__link:hover &__link-text {
		color: $brand-primary_dark; }

	&_light &__link-text {
		color: $white; }

	&_light &__link:hover &__link-text {
		color: $brand-primary; } }
