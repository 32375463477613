.b-heading {
	display: flex; // to collapse margins inside
	flex-direction: column;
	margin-bottom: 70px;

	@include md-tablet {
		margin-bottom: 55px; }

	@include md-mobile {
		margin-bottom: 40px; }

	&_center {
		text-align: center; }

	&_m_b_xxs {
		margin-bottom: 35px;

		@include md-mobile {
			margin-bottom: 30px; } }

	&_m_b_xs {
		margin-bottom: 40px;

		@include md-mobile {
			margin-bottom: 35px; } }

	&_m_b_sm {
		margin-bottom: 50px;

		@include md-tablet {
			margin-bottom: 45px; }

		@include md-mobile {
			margin-bottom: 40px; } }

	&_m_b_xxs_tb {

		@include md-tablet {
			margin-bottom: 35px; }

		@include md-mobile {
			margin-bottom: 30px; } }

	&_m_b_lg {
		margin-bottom: 85px;

		@include md-tablet {
			margin-bottom: 60px; }

		@include md-mobile {
			margin-bottom: 40px; } }

	&_m_b_xl {
		margin-bottom: 100px;

		@include md-tablet {
			margin-bottom: 70px; }

		@include md-mobile {
			margin-bottom: 40px; } }

	&_last {
		margin-bottom: 0; }

	&__title {
		display: flex; // to collapse margins inside
		flex-direction: column;
		margin-bottom: 17px;

		&_m_b_lg {
			margin-bottom: 25px;

			@include md-mobile {
				margin-bottom: 20px; } }

		&:last-child {
			margin-bottom: 0; } }

	&__title-inner {
		display: flex;
		flex-wrap: wrap;
		margin: -6px 0; }

	&_center &__title-inner {
		justify-content: center;
		text-align: center; }

	&__title-text { // tag span
		position: relative;
		margin: 6px 0;

		&::before {
			content: '';

			position: absolute;
			top: 11px;
			left: 0;

			width: 100%;
			height: 6px;

			background: $brand-primary;

			@include md-tablet {
				top: 10px; }

			@include md-mobile {
				top: 9px;
				height: 5px; } } }

	&__title-text-inner { // tag span
		position: relative;
		z-index: 1; // to be over &__title-text::before

		display: block;

		margin: -6px 0;

		color: $black;
		font-size: 40px;
		line-height: 1;
		font-weight: 700;
		letter-spacing: .02em;

		text-transform: uppercase;

		@include md-tablet {
			margin: -5px 0;
			font-size: 36px; }

		@include md-mobile {
			margin: -6px 0 -8px;
			font-size: 30px;
			line-height: 36px; } }

	&__subtitle {
		margin: -7px 0 -8px;

		color: $black;
		font-size: 24px;
		line-height: 32px;
		font-weight: 600;
		letter-spacing: .02em;

		@include md-tablet {
			margin: -6px 0 -8px;
			font-size: 22px;
			line-height: 30px; }

		@include md-mobile {
			margin: -7px 0;
			font-size: 20px;
			line-height: 28px; } } }
