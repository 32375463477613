.b-tabs {
	display: flex; // to collapse margins inside
	flex-direction: column;
	margin-bottom: 40px;

	&_last {
		margin-bottom: 0; }

	&__inner {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		margin: -6px -10px;

		@include md-mobile {
			margin: -6px; } }

	&__item {
		margin: 6px 10px;

		@include md-mobile {
			margin: 6px; } }

	&__btn {
		position: relative; // for &::before
		padding: 13px 20px;
		background: $black;

		&::before {
			content: '';

			position: absolute;
			top: 0;
			left: 0;

			width: 0;
			height: 100%;

			background-color: $brand-primary;

			transition: width .2s; } }

	&__btn:hover::before,
	&__btn_active::before {
		width: 100%; }

	&__text { // tag span
		position: relative;
		z-index: 1; // to be over &__btn::before

		display: block;

		margin: -6px 0 -7px;

		color: $white;
		font-size: 16px;
		line-height: 24px;
		font-weight: 600;
		letter-spacing: .04em; }

	&__btn:hover &__text,
	&__btn_active &__text {
		color: $black; } }
